import React, {useEffect, useState} from "react";
import {getAllTemplates} from "../../business/redux/reducers/template/actions";
import {InformationModal} from "../../components/modal";
import {CustomToolbar} from "./customToolbar";
import {getPaginationText} from "../../business/helpers/util";
import {columns} from './constants';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {CircularProgress, Box} from "@mui/material";
import {CustomPagination} from "../../components/pagination";
import './style.css';

export const Templates = () => {
    const dispatch = useDispatch();
    const {loading, modalData, templates, auth} = useSelector(state => state);
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        dispatch(getAllTemplates({token: auth?.__raw, tableLoading: true}))
    }, [auth?.__raw]);

    if (loading.isLoading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress/>
            </Box>
        )
    }

    const data = templates?.data || []

    const paginationModelChangeHandler = e => dispatch(getAllTemplates({
        token: auth?.__raw,
        paginationParams: {per_page: e.pageSize, page: 1, order: 'desc'},
        tableLoading: true
    }))

    const paginationChangeHandler = (newPage, data) => {

        const paginationParams = {per_page: data?.per_page || 25, page: newPage, order: 'desc'};

        dispatch(getAllTemplates({
            token: auth?.__raw,
            tableLoading: true,
            paginationParams
        }))
    }

    return (
        <Box sx={{width: '100%', height: '88vh'}}>
            <DataGrid
                className="custom_table template_table"
                rows={data.filter(el => el.name.includes(searchText))}
                columns={columns(dispatch)}
                slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination
                }}
                slotProps={{
                    toolbar: {searchText, setSearchText},
                    pagination: {data: templates, onChange: paginationChangeHandler}
                }}
                onPaginationModelChange={paginationModelChangeHandler}
                initialState={{pagination: {paginationModel: {pageSize: 25}}}}
                pageSizeOptions={[25, 50, 100]}
                rowCount={templates?.total}
                getRowClassName={r => r.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                loading={loading?.tableLoading}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Results per page /',
                        labelDisplayedRows: getPaginationText(templates?.per_page || 25, 'templates')
                    }
                }}
            />

            <InformationModal isOpen={modalData?.isOpen} content={modalData?.content} style={modalData?.style}/>
        </Box>
    )
}



