import React, {useRef, useState} from "react";
import {saveTemplate} from "../../business/redux/reducers/template/actions";
import {closeModal} from "../../business/redux/reducers/modal/actions";
import {useDispatch, useSelector} from "react-redux";
import {enqueueSnackbar} from "notistack";
import {
    Box, Button, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem,
    Modal, OutlinedInput, Stack, TextareaAutosize, Typography, ListItemText, TextField, Popover
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {shortcodes} from "../../business/constants";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import {styles} from "./styles";
import {LoadTemplate} from "../modal/informationModal/contents/campaignContent/stepTwoContent/loadTemplate";

export const TemplateCreate = () => {

    const dispatch = useDispatch();
    const textAreaRef = useRef(null);

    const [openLoadTemplate, setOpenLoadTemplate] = useState(false);
    const [template, setTemplate] = useState({name: '', template: ''});
    const [openTemplateName, setOpenTemplateName] = useState(false);

    const {templates, auth} = useSelector(s => s);

    const saveTemplateHandler = () => {
        dispatch(saveTemplate(auth?.__raw, template.name, template.template, enqueueSnackbar, templates));
        dispatch(closeModal());
    }

    return (
        <React.Fragment >

            <Grid container direction="row" height="100%">
                <Grid item xs={8}  alignItems="start" sx={{padding:'20px'}}>
                    <Typography  variant="h2" component="div" fontWeight="600" color="#2B3033" mb={2}>
                        Campaign content
                    </Typography>

                    <Box direction="row" display="flex" justifyContent="space-between">

                        <Button
                            sx={{mb: 3}}
                            className="btn_gray_style"
                            onClick={() => setOpenLoadTemplate(true)}
                        >
                            <UploadFileIcon sx={{mr:1,fontSize:'20px'}}/> Load a template
                        </Button>

                        <Button
                            sx={{mb: 3}}
                            className="btn_gray_style"
                            onClick={() => template.template
                                ? setOpenTemplateName(true)
                                : enqueueSnackbar('Please select template content', {variant: 'warning'})
                            }
                        >
                            <SaveOutlinedIcon sx={{mr:1,fontSize:'20px'}}/> Save
                        </Button>

                    </Box>

                    <TextareaAutosize
                        ref={textAreaRef}
                        className="textarea_style"
                        placeholder="Enter campaign text here"
                        value={template.template}
                        onChange={e => setTemplate({...template, template: e.target.value})}
                    />

                </Grid>
                <Grid  item xs={4}  alignItems="start" bgcolor="#EBECF0" height="100%"  sx={{padding:'20px'}}>
                    <Typography  variant="body2" component="div" fontWeight="500" color="#2B3033" mb={3}>
                        Insert shortcode
                    </Typography>
                    <Box justifyContent="start">
                        {shortcodes.map(shortcode => (
                            <Chip
                                key={shortcode}
                                className="chip_shortcode"
                                label={shortcode}
                                onClick={ () => {
                                    setTemplate(prev => ({
                                        ...template,
                                        template: `${prev.template} ${shortcode}`
                                    }))
                                    textAreaRef.current.focus()
                                }}
                                clickable
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>

            <Modal
                open={openTemplateName}
                onClose={() => setOpenTemplateName(false)}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={styles}>
                    <Typography  sx={{ mb: 3}} variant="h2" component="div" fontWeight="600" color="#2B3033">
                        Title
                    </Typography>
                    <FormControl fullWidth sx={{ mb: 5 }} className="filters_field">
                        <InputLabel htmlFor="template_name">Template name</InputLabel>
                        <OutlinedInput
                            id="template_name"
                            value={ template.name }
                            onChange={ e => setTemplate({...template, name: e.target.value}) }
                            label="Template name"
                        />
                    </FormControl>

                    <Stack display="flex" direction="row" justifyContent="space-between">
                        <Button
                            variant="contained"
                            className="cancle_childModal_btn"
                            onClick={() => setOpenTemplateName(false)}
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="contained"
                            className="view_results_btn active"
                            onClick={saveTemplateHandler}
                        >
                            Save Template
                        </Button>
                    </Stack>

                </Box>
            </Modal>

            <Popover
                open={openLoadTemplate}
                anchorEl={openLoadTemplate}
                className="poper_template"
                marginThreshold={0}
                onClose={ () => setOpenLoadTemplate( !openLoadTemplate) }
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <LoadTemplate
                    templates={templates}
                    setOpenLoadTemplate={setOpenLoadTemplate}
                    clickHandler={t => setTemplate({...template, template: t})}
                />
            </Popover>
        </React.Fragment>
    )
}