import * as React from "react";
import {AssignedLeads, Campaigns, Home, Templates, Logs, Reports} from "../../screens";
import {ErrorComponent} from "../../components/errors";
import {urls} from "./urls";

export const ASSIGNED_LEADS_ROUTE = {
    path: urls.assignedLeads,
    element: <AssignedLeads />
};

export const CAMPAIGNS_ROUTE = {
    path: urls.campaigns,
    element: <Campaigns />
};

export const TEMPLATES_ROUTE = {
    path: urls.templates,
    element: <Templates />
};

export const LOGS_ROUTE = {
    path: urls.logs,
    element: <Logs />
};

export const REPORTS_ROUTE = {
    path: urls.reports,
    element: <Reports />
};

export const ERROR_ROUTE = {
    path: urls.error,
    element: <ErrorComponent />
};

export const HOME_ROUTE = {
    path: urls.home,
    element: <Home />
};

export const PUBLIC_ROUTES = [
    HOME_ROUTE,
    ERROR_ROUTE,
];

export const PRIVATE_ROUTES = [
    ASSIGNED_LEADS_ROUTE,
    CAMPAIGNS_ROUTE,
    TEMPLATES_ROUTE,
    LOGS_ROUTE,
    REPORTS_ROUTE,
];