import React from "react";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import {ASSIGNED_LEADS_ROUTE, ERROR_ROUTE} from "../business/routes/routes";

export const Auth0ProviderWithNavigate = ({ children }) => {

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            // authorizationParams={{
            //     redirect_uri: redirectUri
            // }}
        >
            {children}
        </Auth0Provider>
    );
};