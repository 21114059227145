import React, {Fragment, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLeadFilterParams} from "../../../../business/redux/reducers/filter/actions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import './style.css';
import {TextField} from "@mui/material";

export const MultipleCheckbox = ({el}) => {

    const dispatch = useDispatch();

    const {filters} = useSelector(s => s);

    const {leadFilter} = filters;

    const [renderedData, setRenderedData] = useState(Object.keys(el.fields));

    const changedFilterData = {};

    Object.keys(leadFilter).forEach(key => {
        if (leadFilter[key]) {
            changedFilterData[key] = leadFilter[key]
        }
    })

    const addFields = fieldName => e => {

        const {checked, value} = e.target;

        const data = checked
            ? [...(leadFilter[fieldName] || []), value]
            : (leadFilter[fieldName] || []).filter(el => el !== value)

        dispatch(setLeadFilterParams({...leadFilter, [fieldName]: data}))
    }

    const changeHandler = e => setRenderedData(Object.keys(el.fields).filter(el => (el.toLowerCase()).includes(e.target.value.toLowerCase())))

    return (
        <Fragment>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {el?.searchable &&
                    <TextField
                        label="Search"
                        variant="standard"
                        type="search"
                        style={{width: 300}}
                        onChange={changeHandler}
                    />}
                {renderedData?.map((key, id) => {
                    return (
                        <FormControlLabel
                            className="label_checkbox_filter"
                            key={id}
                            label={el.fields[key]}
                            control={
                                <Checkbox
                                    sx={{p: 0, m: 1}}
                                    value={el?.fields[key] || false}
                                    name={key}
                                    checked={leadFilter[el.fieldName]?.includes(el.fields[key])}
                                    onChange={addFields(el.fieldName)}
                                    icon={<CheckIcon/>}
                                    checkedIcon={<CheckIcon/>}
                                />
                            }
                        />
                    )
                })}
            </Box>
        </Fragment>
    )
}
