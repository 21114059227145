import React from "react";
import {Link} from "@mui/material";

const redirectToZohoSettings = v => (
    <Link
        target="_blank"
        underline="hover"
        className="job_number"
        href={`https://crm.zoho.com/crm/org692830833/settings/users/${v.row.lead_id}`}
        // href={`https://crm.zoho.com/crm/org692830833/tab/Leads/${v.row.zoho_id}`}
    >
        {v.row.user_name}
    </Link>
)

const redirectToZohoLeads = v => (
    <Link
        target="_blank"
        underline="hover"
        className="job_number"
        href={`https://crm.zoho.com/crm/org692830833/tab/Leads/${v.row.zoho_id}`}
    >
        {v.row.job_number}
    </Link>
)

const columns = dispatch => ([
    {field: 'user_name', headerName: 'User Name', flex:1, renderCell: redirectToZohoSettings},
    {field: 'job_number', headerName: 'Job Number', flex:1, renderCell: redirectToZohoLeads},
    {field: 'campaign_name', headerName: 'Campaign Name', flex:1},
    {field: 'event_date', headerName: 'Event Date', flex:1},
    {field: 'job_date', headerName: 'Job Date', flex:1},
    {field: 'status', headerName: 'Status', flex:1, renderCell: v => v.row.status.replace("_", " ")},
    {field: 'type', headerName: 'Type', flex:0.5,},
    {field: 'message', headerName: 'Content', flex:0.5},
]);

export {
    columns,
}