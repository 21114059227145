import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLeadFilterParams} from "../../../../../business/redux/reducers/filter/actions";
import {getAllLeadsByFilter} from "../../../../../business/redux/reducers/leads/actions";
import {closeModal} from "../../../../../business/redux/reducers/modal/actions";
import {FilterTabs} from "./filterTabs";
import {getFilter} from "./helpers";
import {Box, Badge, Typography, Stack, Button} from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


export const FilterContent = () => {

    const dispatch = useDispatch();

    const {leads, filters, auth} = useSelector(s => s);

    const {leadFilter} = filters;

    useEffect(() => {
        if (!Object.keys(leadFilter)?.length) {
            dispatch(setLeadFilterParams(getFilter(leads?.filter)));
        }
    }, []);

    const filterCount = Object.keys(leadFilter)
        .reduce((acc, curr) => {
            return Array.isArray(leadFilter[curr])
                ? acc + leadFilter[curr].length
                : acc + Number(!!leadFilter[curr])
        }, 0);

    return (
        <>
            <Box className="modal-title" sx={{ p: 3}} direction="row" display="flex" justifyContent="space-between">
                <Badge badgeContent={filterCount} color="primary" sx={{mr:2}} className="count_shadow">
                    <Typography  variant="h1" component="div" fontWeight="700" color="#2B3033">Filters</Typography>
                </Badge>
                <Button
                    variant="outlined"
                    color="error"
                    className="remove_all_filters"
                    sx={{py: 0}}
                    startIcon={<HighlightOffIcon/>}
                    onClick={() => dispatch(setLeadFilterParams(getFilter(leads?.filter)))}
                >
                    Remove All Filters
                </Button>
            </Box>

            <FilterTabs/>

            <Stack className="modal-footer" sx={{ py:'10px',px: 2}} display="flex" direction="row" justifyContent="space-between">
                <Button onClick={() => dispatch(closeModal())} variant="contained" className="cancle_modal_btn">Cancel</Button>
                <Button onClick={() => {
                    dispatch(closeModal());
                    dispatch(getAllLeadsByFilter({
                        token: auth?.__raw,
                        filterFormData: leadFilter,
                        tableLoading: true
                    }))
                }} variant="contained" className="view_results_btn active">View Results</Button>
            </Stack>
        </>
    )
}
