import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Pagination, PaginationItem} from "@mui/material";
import {getAllLeadsByFilter} from "../../business/redux/reducers/leads/actions";
import {hasLoading} from "../../business/helpers/util";


export const CustomTablePagination = ({className, data, onChange}) => {

    const {loading} = useSelector(s => s);

    const changeHandler = (e, newPage) => {

        if (hasLoading(loading)) return;

        onChange(newPage, data)
    }

    return (
        <Pagination
            color="primary"
            className={className}
            page={data?.current_page}
            count={data?.last_page || 1}
            onChange={changeHandler}
            renderItem={item => (
                <PaginationItem
                    {...item}
                    slots={{previous: () => "Previous", next: () => "Next"}}
                />
            )}
        />
    );
}