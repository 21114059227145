import * as React from "react";
import {closeModal} from "../../../../../business/redux/reducers/modal/actions";
import {campaignDirectCall, campaignDirectEmail, campaignDirectSms} from "../../../../../business/redux/reducers/campaign/actions";
import {useDispatch, useSelector} from "react-redux";
import {Container, Grid, Box, Typography, Button} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import {DataGrid} from "@mui/x-data-grid";
import {columns} from "./constants";
import {useState} from "react";
import './style.css';

export const ViewCampaignContent = ({lead}) => {

    const dispatch = useDispatch();

    const [messageInfo, setMessageInfo] = useState(false);
    const [message, setMessage] = useState('');

    const {auth} = useSelector(s => s);

    return (
        <>
            <Box className="modal-title" sx={{p:3}} direction="row" display="flex" justifyContent="space-between">
                <Typography  variant="h1" component="div" fontWeight="700" color="#2B3033">
                     Campaigns per lead
                </Typography>
                <Button onClick={() => dispatch(closeModal())} variant="text" sx={{py:0, minWidth:'unset'}}>
                  <HighlightOffIcon sx={{ color: '#2B3033',fontSize:'22px'}}/>
                </Button>
            </Box>
            <Box className="modal-description" direction="row"  sx={{ flexGrow: 1,bgcolor: 'background.paper', height: 'calc(100% - 76px)'}}>
                <Grid  sx={{width:'780px'}}>
                    <Container sx={{padding:'20px'}}>
                        <Grid container spacing={2} direction="row"  mb={4} alignItems="start">
                            <Grid item xs={12} md={6} className="info_job" textAlign="left">
                                <Typography variant="div" component="div" className="job_info_title" mb="4px">
                                    Job <span>#{ lead.Job_Number }</span>
                                </Typography>
                                <Typography variant="body1" component="div" className="job_info_sub">
                                    created on {lead.createdAt}
                                </Typography>

                            </Grid>
                            <Grid item xs={12} md={6} className="info_job" textAlign="right">
                                <Typography variant="div" component="div"  mb="4px" className="job_info_title">
                                    {lead.Last_Name}
                                </Typography>
                                <Typography variant="body1" component="div" className="job_info_sub">
                                    {lead.phone}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container direction="row" alignItems="start">
                            <Grid item xs={12}>
                                <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="center" className="sub_menu_lead">
                                    <Button
                                        sx={{mx: 1}}
                                        variant="outlined"
                                        startIcon={<PhoneOutlinedIcon/>}
                                        onClick={() => dispatch(campaignDirectCall(auth?.__raw, lead?.zoho_id))}
                                    >
                                        Call now
                                    </Button>
                                    <Button
                                        sx={{mx: 1}}
                                        variant="outlined"
                                        startIcon={<SmsOutlinedIcon/>}
                                        onClick={() => dispatch(campaignDirectSms(auth?.__raw, lead?.zoho_id))}
                                    >
                                        Send SMS
                                    </Button>
                                    <Button
                                        sx={{mx: 1}}
                                        variant="outlined"
                                        startIcon={<EmailOutlinedIcon/>}
                                        onClick={() => dispatch(campaignDirectEmail(auth?.__raw, lead?.zoho_id))}
                                    >
                                        Send email
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                    <Container sx={{padding:'0px !important'}}>
                        <Grid container  direction="row" alignItems="start">
                            <Grid item xs={12}>
                                <DataGrid
                                    rows={lead?.campaigns || []}
                                    columns={columns(messageInfo, setMessageInfo, setMessage)}
                                    className="custom_table per_lead_table"
                                    disableRowSelectionOnClick
                                    hideFooterPagination
                                    hideFooterSelectedRowCount
                                    getRowClassName={v => v.indexRelativeToCurrentPage % 2 === 0 ? 'odd' : 'even'}
                                />
                            </Grid>
                        </Grid>
                    </Container>
                </Grid>
                {messageInfo &&
                    <Grid className="messageInfo">
                        <Container sx={{padding:'20px'}}>
                            <Typography variant="div" component="div" className="job_info_sub">
                                {message}
                            </Typography>

                            {/*<Typography variant="div" component="div" className="job_info_sub">*/}
                            {/*    Hello <b>[Mr. Richards],</b>*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="div" component="div" className="job_info_sub" mb={3}>*/}
                            {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nec mi gravida ante vestibulum mollis. Curabitur lorem eros, eleifend in rhoncus at, aliquam sit amet urna. Praesent facilisis massa velit, vel egestas dolor lobortis non.*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="div" component="div" className="job_info_sub"mb={3}>*/}
                            {/*    Nunc ut ipsum euismod, suscipit magna molestie, imperdiet leo.*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="div" component="div" className="job_info_sub" mb={3}>*/}
                            {/*    The total price of the service would be <b>[$12,950].</b>*/}
                            {/*</Typography>*/}
                            {/*<Typography variant="div" component="div" className="job_info_sub" fontWeight="700">*/}
                            {/*    [Best regards,*/}
                            {/*    Ken Connor*/}
                            {/*    Roadway Moving]*/}
                            {/*</Typography>*/}
                        </Container>
                    </Grid>
                }
            </Box>
        </>
    )
}
