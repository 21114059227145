import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setLeadFilterParams} from "../../../business/redux/reducers/filter/actions";
import {Box, Typography, Button, OutlinedInput, InputLabel, FormControl, InputAdornment} from "@mui/material";

export const CubicFitRange = ({ el }) => {

    const dispatch = useDispatch();

    const {filters} = useSelector(s => s);

    const {leadFilter} = filters;

    return (
        <Box sx={{flexGrow: 1}}>
            <Typography variant="p" component="div" fontWeight="500" fontStyle="italic" color="#A8ADB0" >
                Choose a size range by selecting one of the shortcuts below, or enter a custom size range in the input fields.
            </Typography>
            <Box display="flex" flexWrap="wrap" sx={{my: 2}}>
                <Button
                    onClick={() => dispatch(setLeadFilterParams({
                        ...leadFilter,
                        [`${el.fieldName}_from`]: 0,
                        [`${el.fieldName}_to`]: 50
                    }))}
                    variant="text"
                    className="chip_filter_btn"
                    sx={{mb: 2, mr: 1}}
                >
                    Less than 50 {el.unit}
                </Button>
                <Button
                    onClick={() => dispatch(setLeadFilterParams({
                        ...leadFilter,
                        [`${el.fieldName}_from`]: 50,
                        [`${el.fieldName}_to`]: 100
                    }))}
                    variant="text"
                    className="chip_filter_btn"
                    sx={{mb: 2, mr: 1}}
                >
                    50 - 100 {el.unit}
                </Button>
                <Button
                    onClick={() => dispatch(setLeadFilterParams({
                        ...leadFilter,
                        [`${el.fieldName}_from`]: 100,
                        [`${el.fieldName}_to`]: 9999999
                    }))}
                    variant="text"
                    className="chip_filter_btn"
                    sx={{mb: 2, mr: 1}}
                >
                    More than 100 {el.unit}
                </Button>
            </Box>
            <Box sx={{flexGrow: 1}}>
                <FormControl fullWidth sx={{mb:3}} variant="outlined" className="filters_field">
                    <InputLabel>From</InputLabel>
                    <OutlinedInput
                        id="between_range"
                        type={'number'}
                        value={leadFilter[`${el.fieldName}_from`] === 0 ? 0 : (leadFilter[`${el.fieldName}_from`] || '')}
                        onChange={e => dispatch(setLeadFilterParams({
                            ...leadFilter,
                            [`${el.fieldName}_from`]: Number(e.target.value) || 0,
                        }))}
                        endAdornment={<InputAdornment position="end" >{el.unit}</InputAdornment>}
                        label="Between"
                    />
                </FormControl>
                <FormControl fullWidth sx={{mb:3}} variant="outlined" className="filters_field">
                    <InputLabel >To</InputLabel>
                    <OutlinedInput
                        id="end_range"
                        type={'number'}
                        value={leadFilter[`${el.fieldName}_to`] === 0 ? 0 : (leadFilter[`${el.fieldName}_to`] || '')}
                        onChange={e => dispatch(setLeadFilterParams({
                            ...leadFilter,
                            [`${el.fieldName}_to`]: Number(e.target.value) || 0,
                        }))}
                        endAdornment={<InputAdornment position="end">{el.unit}</InputAdornment>}
                        label="And"
                    />
                </FormControl>
            </Box>
        </Box>
    )
}
