import * as React from 'react';
import {useDispatch} from "react-redux";
import {closeModal} from "../../../business/redux/reducers/modal/actions";
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {Fade} from "./fade";
import './style.css';

const defaultStyle = {
    position: 'absolute',
    top: '0',
    right: '0',
    width:'inherit',
    maxWidth:'780px',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: '-10px 0 30px rgba(0,0,0,0.3)',
    p: 0,
};

export const InformationModal = ({isOpen = false, content = <></>, style = defaultStyle,}) => {

    const dispatch = useDispatch();

    return (
        <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            open={isOpen}
            onClose={() => dispatch(closeModal())}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{backdrop: {TransitionComponent: Fade}}}
        >
            <Fade in={isOpen}>
                <Box sx={{...defaultStyle,...style}}>
                    {content}
                </Box>
            </Fade>
        </Modal>
    );
}

