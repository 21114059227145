import * as React from 'react';
import {Link} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const columns = (messageInfo, setMessageInfo, setMessage) => ([
    {
        field: 'name',
        headerName: 'Campaign Title',
        type: 'string',
        flex: 1.2,
        renderCell: v => <Typography variant="div" component="div">{v.row.name}</Typography>
    },
    {
        field: 'started_date',
        headerName: 'Date',
        type: 'string',
        flex:0.8,
        renderCell: v => <Box>{v.row.started_date}</Box>
    },
    {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        flex:0.8,
        renderCell: v => <Typography variant="div" component="div" fontWeight="700">{v.row.type}</Typography>
    },
    // {
    //     field: 'Lead_Stage',
    //     headerName: 'Stage',
    //     type: 'string',
    //     flex:1,
    //     // class colors- green_text,orange_text,red_text
    //     renderCell: v => <Typography variant="div" component="div">{v.row.Lead_Stage}</Typography>
    // },
    // {
    //     field: 'details',
    //     headerName: 'Details',
    //     type: 'string',
    //     flex:1,
    //     renderCell: v => <Typography variant="div" component="div">{v.row.details}</Typography>
    // },
    {
        field: 'settings',
        sortable: false,
        disableColumnMenu: true,
        flex: 0.5,
        renderCell: v => (
            <>
                {
                    v.row.type === 'call' ||
                    <Link
                        component="button"
                        underline="none"
                        className={'view_btn_style' + (messageInfo ? ' red_text' : "")}
                        onClick={() => {
                            setMessageInfo(!messageInfo);
                            setMessage(v.row?.settings?.template)
                        }}
                    >
                        {messageInfo ? 'Close' : 'View'}
                    </Link>
                }
            </>
        )
    }
])

const initialCampaignCreateState = {
    leads: [],
    name: '',
    type: 'sms',
    settings: { template: '' },
    userId: 4064759000069945023,
}

export {
    columns,
    initialCampaignCreateState,
}
