export const LOADING_SHOW = 'LOADING_SHOW';
export const CUSTOM_LOADING_SHOW = 'CUSTOM_LOADING_SHOW';
export const LOADING_HIDE = 'LOADING_HIDE';
export const CUSTOM_LOADING_HIDE = 'CUSTOM_LOADING_HIDE';
export const TABLE_LOADING_SHOW = 'TABLE_LOADING_SHOW';
export const TABLE_LOADING_HIDE = 'TABLE_LOADING_HIDE';
export const TEXTAREA_LOADING_SHOW = 'TEXTAREA_LOADING_SHOW';
export const TEXTAREA_LOADING_HIDE = 'TEXTAREA_LOADING_HIDE';
export const LOADING_INIT_STATE = {
    isLoading: false,
    customLoading: false,
    tableLoading: false,
    textareaLoading: false,
};
