import React from "react";
import {setLeadFilterParams} from "../../../business/redux/reducers/filter/actions";
import {useDispatch, useSelector} from "react-redux";
import {Box, FormControl, InputAdornment, InputLabel, OutlinedInput, Typography} from "@mui/material";

export const SearchByField = ({el}) => {

    const dispatch = useDispatch();

    const {filters} = useSelector(s => s);

    const {leadFilter} = filters;

    return (
        <Box sx={{flexGrow: 1}}>
            <Typography style={{marginBottom: 20}} variant="p" component="div" fontWeight="500" fontStyle="italic" color="#A8ADB0">
                {el?.description}
            </Typography>
            <Box sx={{flexGrow: 1}}>
                <FormControl fullWidth sx={{mb:3}} variant="outlined" className="filters_field">
                    <InputLabel>Serach...</InputLabel>
                    <OutlinedInput
                        id="between_range"
                        type={'text'}
                        value={leadFilter[el?.fieldName || '']}
                        onChange={e => dispatch(setLeadFilterParams({
                            ...leadFilter,
                            [el.fieldName]: e.target.value
                        }))}
                        endAdornment={<InputAdornment position="end" >{el.unit}</InputAdornment>}fgh
                        label="Between"
                    />
                </FormControl>
            </Box>
        </Box>
    )
}