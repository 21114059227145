import {
    LOADING_HIDE,
    LOADING_INIT_STATE,
    LOADING_SHOW,
    CUSTOM_LOADING_SHOW,
    CUSTOM_LOADING_HIDE,
    TABLE_LOADING_SHOW,
    TABLE_LOADING_HIDE,
    TEXTAREA_LOADING_SHOW,
    TEXTAREA_LOADING_HIDE,
} from "./types";

export const loadingReducer = (state = LOADING_INIT_STATE, action) => {
    switch (action.type) {
        case LOADING_SHOW:return {...state, isLoading: true};
        case LOADING_HIDE:return {...state, isLoading: false};
        case CUSTOM_LOADING_SHOW:return {...state, customLoading: true};
        case CUSTOM_LOADING_HIDE:return {...state, customLoading: false};
        case TABLE_LOADING_SHOW:return {...state, tableLoading: true};
        case TABLE_LOADING_HIDE:return {...state, tableLoading: false};
        case TEXTAREA_LOADING_SHOW:return {...state, textareaLoading: true};
        case TEXTAREA_LOADING_HIDE:return {...state, textareaLoading: false};
        default: return state
    }
}