import * as React from 'react';
import Typography from "@mui/material/Typography";
import {useAuth0} from "@auth0/auth0-react";

export const NotFoundComponent = () => (
    <Typography variant={'h1'} justify={'center'} align={'center'}>
        <br/><br/><br/><br/>
        <h1>404 !!!!</h1><br/><br/><br/>
        <h1>PAGE NOT FOUND</h1>
    </Typography>
);
