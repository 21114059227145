import React from "react";
import {NavLink} from "react-router-dom";
import {Box, Container, Grid, Typography} from "@mui/material";
import {urls} from "../../business/routes/urls";

export const HeaderNavigationSection = () => {

    const navigationData = [
        {url: urls.assignedLeads, text: 'LEADS'},
        {url: urls.campaigns, text: 'CAMPAIGNS'},
        {url: urls.templates, text: 'TEMPLATES'},
        {url: urls.logs, text: 'LOGS'},
        {url: urls.reports, text: 'REPORTS'},
    ]

    return (
        <Box sx={{width: "100%", height: "auto", backgroundColor: "#F4F5F8", boxShadow: '0 7px 15px rgba(0,0,0,0.03)'}}>
            <Container maxWidth="lg">
                <Grid container direction="row" justifyContent="center">
                    {navigationData.map(el => (
                        <Typography key={el.url} color="#2B3033" variant="h3" fontWeight="500" className="tab_link">
                            <NavLink to={el.url}>{el.text}</NavLink>
                        </Typography>
                    ))}
                </Grid>
            </Container>
        </Box>
    )
}