import {Box, IconButton, List, ListItem, ListItemText, Typography} from "@mui/material";
import {ArrowRightAlt} from "@mui/icons-material";
import moment from "moment";
import React from "react";

export const TemplateList = ({templates, clickHandler}) => {
    return (
        <List sx={{height:'100%',overflow: 'auto', width: '100%',pr:'10px',py:'0px'}}>
            {
                (templates || []).map( template => (
                    <ListItem key={template.id} alignItems="flex-start" className={`template_list_style ${true ? '' : 'active_temp'}`}>
                        <ListItemText
                            primary={
                                <Box display="flex" direction="row" justifyContent="space-between">
                                    <Typography
                                        component="span"
                                        variant="div"
                                        className="list_template_name"
                                    >
                                        {template.name}
                                    </Typography>

                                    <IconButton
                                        onClick={() => clickHandler(template.template)}
                                        sx={{padding:'0px'}}
                                    >
                                        <ArrowRightAlt sx={{color:'#007BFF', fontSize:'24px'}}/>
                                    </IconButton>
                                </Box>
                            }
                            secondary = {
                                <Box display="flex" direction="row" justifyContent="space-between">
                                    <Typography
                                        component="span"
                                        variant="h6"
                                        className="temp_create_date"
                                    >
                                        Created {moment(template.created_at).format('ll')}
                                    </Typography>
                                    <Typography
                                        component="span"
                                        variant="h6"
                                        className="temp_create_date"
                                    >Used 3 times
                                    </Typography>
                                </Box>
                            }
                        />
                    </ListItem>
                ))
            }
        </List>
    )
}