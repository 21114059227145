import {baseAxios} from "../../../../services/api";
import {getUrl} from "../../../../services/helper";
import {LOADING_HIDE, LOADING_SHOW, TABLE_LOADING_HIDE, TABLE_LOADING_SHOW} from '../loading/types';
import {api} from '../../../../configs';
import {SET_LOGS} from "./types";

const getAllCampaignLogs = ({
                             token,
                             paginationParams = {
                                 per_page: 25,
                                 page: 1,
                                 order: 'desc'
                             },
                             showLoading = false,
                             tableLoading = false
                         }) => async dispatch => {
    try {
        if (!token) return null;

        showLoading && dispatch({type: LOADING_SHOW})
        tableLoading && dispatch({type: TABLE_LOADING_SHOW})

        const {data: logs} = await baseAxios(token).get(getUrl(api.campaign_log.index), {params: paginationParams});

        dispatch({type: SET_LOGS, logs});
    } catch (error) {
        console.error("Error fetching campaigns:", error.message);
    } finally {
        showLoading && dispatch({type: LOADING_HIDE})
        tableLoading && dispatch({type: TABLE_LOADING_HIDE})
    }
};

export {
    getAllCampaignLogs,
}

