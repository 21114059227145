import * as React from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {a11yProps} from "../../../../tabPanel/functionHelpers";
import {TabPanel} from "../../../../tabPanel/tabPanel";
import {FilterContentByData} from "../filterContent/filterContentByData";
import {setLeadFilterParams} from "../../../../../business/redux/reducers/filter/actions";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const FilterTabs = () => {

    const dispatch = useDispatch();

    const {leads} = useSelector(state => state);

    const [value, setValue] = useState(0);

    const handleChange = (_, newValue) => setValue(newValue);

    const {filters} = useSelector(s => s);

    const {leadFilter} = filters;

    return (
        <Box
            className="modal-description"
            sx={{flexGrow: 1, bgcolor: 'background.paper'}}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Filter Tab"
                className="Filter_Tab"
                indicatorColor="transparent"
                sx={{background: '#EBECF0', p: 2, minWidth: '320px'}}
            >
                {leads?.filter.map((el, idx) => {

                        const data = {...leadFilter};

                        if (Boolean(data[el.fieldName]) && Array.isArray(data[el.fieldName])) {
                            data[el.fieldName] = []
                        } else if (Boolean(data[el.fieldName]) && typeof data[el.fieldName] === "string") {
                            data[el.fieldName] = ''
                        } else {
                            delete data[el.fieldName];
                        }

                        delete data[`${el.fieldName}_from`];
                        delete data[`${el.fieldName}_to`];


                        const highlightOffIcon = Boolean(
                            (['checkbox', 'input'].includes(el.type) && leadFilter[el.fieldName]?.length) ||
                            (['date_range', 'number_range'].includes(el.type) && (leadFilter[`${el.fieldName}_from`] || leadFilter[`${el.fieldName}_to`]))
                        )

                        return (
                            <Tab
                                sx={{py: 0, px: 2, mb: 1}}
                                className="filter_tab_btn"
                                label={el.displayField}
                                icon={highlightOffIcon ? <HighlightOffIcon color={'error'}
                                                                           onClick={() => dispatch(setLeadFilterParams(data))}/> : <></>}
                                iconPosition={'end'}
                                {...a11yProps(idx)}
                            />
                        );
                    }
                )}
            </Tabs>

            {leads?.filter?.map((el, idx) => (
                <TabPanel
                    key={idx}
                    value={value}
                    index={idx}
                >
                    <FilterContentByData key={idx} el={el}/>
                </TabPanel>
            ))}
        </Box>
    );
}
