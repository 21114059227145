import {
    CUSTOM_LOADING_HIDE,
    CUSTOM_LOADING_SHOW,
    LOADING_HIDE,
    LOADING_SHOW,
    TABLE_LOADING_HIDE,
    TABLE_LOADING_SHOW,
    TEXTAREA_LOADING_HIDE, TEXTAREA_LOADING_SHOW
} from '../loading/types';
import {GET_LEADS_SUCCESS} from "./types";
import {api} from '../../../../configs/index';
import axios from "axios";
import {getUrl} from "../../../../services/helper";
import {filterG} from "../../../../components/modal/informationModal/contents/leadsFilter/helpers";
import {baseAxios} from "../../../../services/api";
import {enqueueSnackbar} from "notistack";

export const getAllLeadsByFilter = ({
                                        token,
                                        paginationParams = {
                                            per_page: 25,
                                            page: 1,
                                            order: 'desc'
                                        },
                                        filterFormData = {},
                                        showLoading = false,
                                        tableLoading = false
}) => async dispatch => {

    if (!token) return null;

    showLoading && dispatch({type: LOADING_SHOW})
    tableLoading && dispatch({type: TABLE_LOADING_SHOW})

    const response = await baseAxios(token).get(
        api.leads.index,
        {params: {...filterG(filterFormData), ...paginationParams}}
    )

    showLoading && dispatch({type: LOADING_HIDE})
    tableLoading && dispatch({type: TABLE_LOADING_HIDE})

    dispatch({
        type: GET_LEADS_SUCCESS,
        leads: response.data
    });
}

export const callLead = async (token, id) => {

    const response = await baseAxios(token).get(getUrl(api.leads.call_lead, id))

    if (!response?.data) return enqueueSnackbar(response?.data?.message || 'Something Went Wrong', { variant: (response?.data?.success ? 'default' : 'error') })

    return enqueueSnackbar( response?.data?.message || '', { variant: response?.data?.success } );
}

export const sendSmsLead = async (token, id) => {

    const response = await axios.get(getUrl(api.leads.send_sms, id))

    if (!response?.data) return enqueueSnackbar(response?.data?.message || 'Something Went Wrong', { variant: (response?.data?.success ? 'default' : 'error') })

    return enqueueSnackbar( response?.data?.message || '', { variant: response?.data?.success } );
}

export const directSmsOrEmail = ({token, zoho_id, template, subject, type}) => async dispatch => {

    dispatch({type: TEXTAREA_LOADING_SHOW})

    const url = type === 'sms' ? api.leads.direct_sms : api.leads.direct_email

    const formData = {
        zoho_id,
        template,
        ...(type === 'email' ? {subject} : {})
    }

    const response = await baseAxios(token).post(url, formData)

    dispatch({type: TEXTAREA_LOADING_HIDE})

    if (!response?.data) return enqueueSnackbar(response?.data?.message || 'Something Went Wrong', { variant: (response?.data?.success ? 'default' : 'error') })

    return enqueueSnackbar( response?.data?.message || '', { variant: response?.data?.success } );
}
