import {LOADING_HIDE, LOADING_SHOW, TABLE_LOADING_HIDE, TABLE_LOADING_SHOW} from '../loading/types';
import {GET_CAMPAIGNS_SUCCESS} from "./types";
import {api} from '../../../../configs';
import {CLOSE_MODAL} from "../modal/types";
import axios from "axios";
import {getUrl} from "../../../../services/helper";
import {baseAxios} from "../../../../services/api";
import {enqueueSnackbar} from "notistack";

const getAllCampaigns = ({
                             token,
                             paginationParams = {
                                 per_page: 25,
                                 page: 1,
                                 order: 'desc'
                             },
                             showLoading = false,
                             tableLoading = false
                         }) => async dispatch => {
    try {
        if (!token) return null;

        showLoading && dispatch({type: LOADING_SHOW})
        tableLoading && dispatch({type: TABLE_LOADING_SHOW})

        const {data: campaigns} = await baseAxios(token).get(getUrl(api.campaign.index), {params: paginationParams});

        dispatch({type: GET_CAMPAIGNS_SUCCESS, campaigns});
    } catch (error) {
        console.error("Error fetching campaigns:", error.message);
    } finally {
        showLoading && dispatch({type: LOADING_HIDE})
        tableLoading && dispatch({type: TABLE_LOADING_HIDE})
    }
};

const startCampaign = (token, id, enqueueSnack, campaigns) => async (dispatch)  => {

    if (!token || !id) return null;

    dispatch({ type: CLOSE_MODAL });

    dispatch({ type: TABLE_LOADING_SHOW });

    const response = await baseAxios(token).get(getUrl(api.campaign.start, id));

    dispatch({ type: TABLE_LOADING_HIDE });

    if (!response?.data) return enqueueSnackbar(response?.data?.message || 'Something Went Wrong', { variant: (response?.data?.success ? 'default' : 'error') })

    dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        campaigns: {
            ...campaigns,
            list: campaigns.list.map(c => (c.id === response.data.data.id ? response.data.data : c))
        }
    });

    enqueueSnackbar(response?.data?.message || '', { variant: response?.data?.success });

}

const campaignPause = (token, id, enqueueSnack, campaigns) => async (dispatch)  => {

    if (!token) return null;

    dispatch({ type: TABLE_LOADING_SHOW });

    dispatch({ type: CLOSE_MODAL });

    const response = await baseAxios(token).get(getUrl(api.campaign.pause, id))
        .catch(error => enqueueSnack(error?.message || '', {variant: 'error'}));

    dispatch({ type: TABLE_LOADING_HIDE });

    if (response === undefined) return;

    dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        campaigns: {
            ...campaigns,
            list: campaigns.list.map(c => (c.id === response.data.data.id ? response.data.data : c))
        }
    });

    enqueueSnack(response?.data?.message || '', { variant: response?.data?.success });

}

const campaignCancel =  (token, id, enqueueSnack, campaigns) => async dispatch =>   {

    dispatch({ type: CLOSE_MODAL });

    dispatch({ type: TABLE_LOADING_SHOW });

    const response = await baseAxios(token).get(getUrl(api.campaign.cancel, id))
        .catch(error => enqueueSnack( error?.message || '', {variant: 'error'}));

    dispatch({ type: TABLE_LOADING_HIDE });

    if (!response) return;

    enqueueSnack(response?.data?.message || '', {variant: response?.data?.success});

    dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        campaigns: {
            ...campaigns,
            list: campaigns.list.map(c => (c.id === response.data.data.id ? response.data.data : c))
        }
    });
}

const campaignDelete =  (token, id, campaigns) => async dispatch => {

    dispatch({ type: TABLE_LOADING_SHOW });

    dispatch({ type: CLOSE_MODAL });

    const response = await baseAxios(token).delete(getUrl(api.campaign.delete, id))
        .catch(error => enqueueSnackbar( error?.message || '', {variant: 'error'}));

    dispatch({ type: TABLE_LOADING_HIDE });

    if (!response) return;

    if (response?.data?.success) {
        enqueueSnackbar(response?.data?.message || '', {variant: response?.data?.success});
    }

    dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        campaigns: {
            ...campaigns,
            list: campaigns.list.filter(c => c.id !== id)
        }
    });
}

const campaignCreate = (token, data, enqueueSnack, loading) => async dispatch => {
    try {
        dispatch({ type: CLOSE_MODAL });

        loading && dispatch({type: LOADING_SHOW})

        const { data: responseData } = await baseAxios(token).post(getUrl(api.campaign.create), data);

        enqueueSnack(
            responseData.message || 'Campaign created successfully.',
            { variant: responseData.success ? 'success' : 'error' }
        );
    } catch (error) {
        enqueueSnack(error.message || 'An error occurred while creating the campaign.', { variant: 'error' });
    } finally {
        loading && dispatch({type: LOADING_HIDE})
    }
};

const campaignUpdate =  (token, data, callback) => async dispatch => {

    const {enqueueSnack, campaigns, setNCampaign} = callback

    const response = await baseAxios(token).put( getUrl( api.campaign.update, data?.id ), data ).catch( error => {
        enqueueSnack( error?.message || '', {variant: 'error'} )
    });

    if (response === undefined) return;

    enqueueSnack( response?.data?.message || '', { variant: response?.data?.success } );

    if (setNCampaign) {
        setNCampaign(response.data.data)
    }

    dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        campaigns: {
            ...campaigns,
            list: campaigns.list.map(c => (c.id === response.data.data.id ? response.data.data : c))
        }
    });
}

const campaignDirectCall = (token, leadId) => async dispatch => {

    try {
        const {data} = (await baseAxios(token).post(getUrl(api.campaign.direct_call), {zoho_id: leadId}));

        dispatch({type: CLOSE_MODAL});

        // callback(
        //     responseData.message || 'Campaign created successfully.',
        //     {variant: responseData.success ? 'success' : 'error'}
        // );
    } catch (error) {
        // callback(error.message || 'An error occurred while creating the campaign.', {variant: 'error'});
    }
};

const campaignDirectSms = (token, zohoId) => async dispatch => {

    try {
        const {data} = (await baseAxios(token).post(getUrl(api.campaign.direct_sms), {zoho_id: zohoId}));

        dispatch({type: CLOSE_MODAL});

        // callback(
        //     responseData.message || 'Campaign created successfully.',
        //     {variant: responseData.success ? 'success' : 'error'}
        // );
    } catch (error) {
        // callback(error.message || 'An error occurred while creating the campaign.', {variant: 'error'});
    }
};

const campaignDirectEmail = (token, zohoId) => async dispatch => {

    try {
        const {data} = (await baseAxios(token).post(getUrl(api.campaign.direct_sms), {zoho_id: zohoId}));

        dispatch({type: CLOSE_MODAL});

        // callback(
        //     responseData.message || 'Campaign created successfully.',
        //     {variant: responseData.success ? 'success' : 'error'}
        // );
    } catch (error) {
        // callback(error.message || 'An error occurred while creating the campaign.', {variant: 'error'});
    }
};

export {
    getAllCampaigns,
    startCampaign,
    campaignPause,
    campaignCreate,
    campaignUpdate,
    campaignCancel,
    campaignDirectCall,
    campaignDirectSms,
    campaignDirectEmail,
    campaignDelete,
}

