import * as React from 'react';
import {BrowserRouter, Navigate, Route, Routes, useNavigate} from "react-router-dom";
import {Provider} from "react-redux";
import {ASSIGNED_LEADS_ROUTE, PRIVATE_ROUTES} from "./product/business/routes/routes";
import store from "./product/business/redux";
import {Home} from "./product/screens";
import {PrivateRoutesLayout} from "./product/components/layouts";
import {ErrorComponent, NotFoundComponent} from "./product/components/errors";
import {useAuth0} from "@auth0/auth0-react";
import {Box, CircularProgress} from "@mui/material";
import {getAuth} from "./product/business/redux/reducers/auth/actions";
import {useEffect} from "react";

export const App = () => {

    const {isAuthenticated, isLoading, auth} = useAuth0();

    if (isLoading) {
        return (
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Provider store={store}>
            <BrowserRouter>
                <Routes>
                    {/*{isAuthenticated &&*/}
                        <Route path="/" element={<PrivateRoutesLayout/>}>
                            <Route index element={<Navigate to={ASSIGNED_LEADS_ROUTE.path} />}/>
                            {PRIVATE_ROUTES.map(route =>
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={route.element}
                                />
                            )}
                            <Route path="*" element={<NotFoundComponent/>}/>
                        </Route>
                {/*}*/}

                    {/*{PUBLIC_ROUTES.map(route =>*/}
                    {/*    <Route*/}
                    {/*        key={route.path}*/}
                    {/*        path={route.path}*/}
                    {/*        element={route.element}*/}
                    {/*    />*/}
                    {/*)}*/}
                    <Route path="*" element={<ErrorComponent/>}/>
                </Routes>
            </BrowserRouter>
        </Provider>
    );
}
