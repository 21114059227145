import React from "react";
import {useDispatch} from "react-redux";
import {closeModal} from "../../../../../business/redux/reducers/modal/actions";
import {Box, Button, Typography} from "@mui/material";
import {SmsOutlined, HighlightOff} from "@mui/icons-material";

export const NewCampaignCreateHeader = ({type}) => {

    const dispatch = useDispatch();

    return (
        <Box className="modal-title" sx={{p: 3}} direction="row" display="flex" justifyContent="space-between">
            <Typography  variant="h1" component="div" fontWeight="700" color="#2B3033">
                <SmsOutlined sx={{mr: 1, fontSize: '24px', color: '#007BFF'}}/> New {type} campaign
            </Typography>
            <Button onClick={() => dispatch(closeModal())} variant="text" sx={{py:0, minWidth:'unset'}}>
                <HighlightOff sx={{ color: '#2B3033',fontSize: '22px'}}/>
            </Button>
        </Box>
    )
}