import React, {useEffect, useState} from "react";
import {getAllCampaigns} from "../../business/redux/reducers/campaign/actions";
import {getPaginationText} from "../../business/helpers/util";
import {columns} from './columns';
import {DataGrid} from "@mui/x-data-grid";
import {CampaignToolbar} from "./campaignToolbar";
import {CustomPagination} from "../../components/pagination";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Box} from "@mui/material";
import './style.css';


export const Campaigns = () => {

    const dispatch = useDispatch();
    const {loading, campaigns, auth} = useSelector(s => s);

    const [filterParams, setFilterParams] = useState({
        statuses: {
            not_started: {checked: true, name: "Not Started"},
            completed: {checked: true, name: "Completed"}
        },
        types: {
            call: {checked: true, name: "CALL"},
            email: {checked: true, name: "EMAIL"},
            sms: {checked: true, name: "SMS"}
        }
    });

    // const campaignTypes = {
    //     types: {all: true},
    //     statuses: {}
    // };

    // useEffect(() => {
    //     if (campaigns) {
    //         getUniqValuesByKey(campaigns?.list, 'type').forEach(type => campaignTypes.types[type] = true)
    //         getUniqValuesByKey(campaigns?.list, 'status').forEach((status, idx) => campaignTypes.statuses[status] = (idx === 0))
    //         setFilterParams(campaignTypes)
    //     }
    // }, [campaigns?.total_size]);

    useEffect(() => {
        dispatch(getAllCampaigns({token: auth?.__raw, tableLoading: true}));
    }, [auth?.__raw]);

    if (loading.isLoading)
        return <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></Box>

    const filteredData =
        campaigns?.list || [];
        // filterParams && !filterParams.types.all
        // ? (campaigns?.list || [])
        //     .filter(campaign => Boolean(filterParams && filterParams.types[campaign.type]) && filterParams.statuses[campaign.status])
        // : campaigns?.list || []

    const paginationModelChangeHandler = e => dispatch(getAllCampaigns({
        token: auth?.__raw,
        paginationParams: {per_page: e.pageSize, page: 1, order: 'desc'},
        tableLoading: true
    }))

    const paginationChangeHandler = (newPage, data) => {

        const paginationParams = {per_page: data?.per_page || 25, page: newPage, order: 'desc'};

        dispatch(getAllCampaigns({
            token: auth?.__raw,
            tableLoading: true,
            paginationParams
        }))
    }

    return (
        <Box sx={{width: '100%', height: '88vh'}}>
            <DataGrid
                className="custom_table campaigns_table"
                rows={filteredData}
                columns={columns(dispatch)}
                slots={{
                    toolbar: CampaignToolbar,
                    pagination: CustomPagination
                }}
                slotProps={{
                    toolbar: {filterParams, setFilterParams},
                    pagination: {data: campaigns, onChange: paginationChangeHandler}
                }}
                onPaginationModelChange={paginationModelChangeHandler}
                initialState={{pagination: {paginationModel: {pageSize: 25}}}}
                pageSizeOptions={[25, 50, 100]}
                rowCount={campaigns?.total_size}
                getRowClassName={r => r.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                loading={loading?.tableLoading}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Results per page /',
                        labelDisplayedRows: getPaginationText(campaigns?.per_page || 25, 'campaigns')
                    }
                }}
            />
        </Box>
    )
}