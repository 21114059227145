import React from "react";
import {GridPagination} from "@mui/x-data-grid";
import {CustomTablePagination} from "./customTablePagination";

export const CustomPagination = ({data, onChange, ...props}) => (
    <GridPagination
        {...props}
        page={data?.current_page || 0}
        ActionsComponent={props =>
            <CustomTablePagination
                {...props}
                data={data}
                onChange={onChange}
            />
        }
    />
)