import React, {Fragment} from "react";
import {useDispatch} from "react-redux";
import {TemplateCreate} from "../../../../utils";
import {closeModal} from "../../../../../business/redux/reducers/modal/actions";
import {Box, Typography, Button} from "@mui/material";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export const TemplateCreateContent = () => {

    const dispatch = useDispatch();

    return (
        <Fragment>
            <Box className="modal-title" sx={{p:3}} direction="row" display="flex" justifyContent="space-between">
                <Typography  variant="h1" component="div" fontWeight="700" color="#2B3033">
                    <SmsOutlinedIcon sx={{mr:1,fontSize:'24px',color:'#007BFF'}}/> New SMS campaign
                </Typography>
                <Button onClick={() => dispatch(closeModal())} variant="text" sx={{py:0, minWidth:'unset'}}>
                    <HighlightOffIcon sx={{ color: '#2B3033',fontSize:'22px'}}/>
                </Button>
            </Box>
            <Box
                className="modal-description"
                direction="row"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    height: 'calc(100% - 132px)'
                }}
            >

                <TemplateCreate/>

            </Box>
        </Fragment>
    )
}