import React from "react";
import {Link} from "@mui/material";

const redirectToZohoSettings = v => (
    <Link
        target="_blank"
        underline="hover"
        className="job_number"
        href={`https://crm.zoho.com/crm/org692830833/settings/users/${v.row.lead_id}`}
        // href={`https://crm.zoho.com/crm/org692830833/tab/Leads/${v.row.zoho_id}`}
    >
        {v.row.user_name}
    </Link>
)

const redirectToZohoLeads = v => (
    <Link
        target="_blank"
        underline="hover"
        className="job_number"
        href={`https://crm.zoho.com/crm/org692830833/tab/Leads/${v.row.zoho_id}`}
    >
        {v.row.job_number}
    </Link>
)

const reportsTableColumns = [
    {field: 'user_name', headerName: 'User Name', flex:1},
    {field: 'user_id', headerName: 'User Id', flex:1},
    {field: 'call_count', headerName: 'Call Count', flex:1},
    {field: 'email_count', headerName: 'Email Count', flex:1},
    {field: 'sms_count', headerName: 'Sms Count', flex:1},
];

export {
    reportsTableColumns,
}