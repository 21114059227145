import {combineReducers} from 'redux';
import {modalReducer} from "./reducers/modal";
import {loadingReducer} from "./reducers/loading";
import {leadsReducer} from './reducers/leads';
import {campaignReducer} from './reducers/campaign';
import {templatesReducer} from './reducers/template';
import {campaignLeadsRelation} from "./reducers/campaign_leads/reducer";
import {filtersReducer} from "./reducers/filter";
import {authReducer} from "./reducers/auth";
import {logReducer} from "./reducers/log";
import {reportsReducer} from "./reducers/reports";

export default combineReducers({
    modalData: modalReducer,
    loading: loadingReducer,
    leads: leadsReducer,
    logs: logReducer,
    campaigns: campaignReducer,
    templates: templatesReducer,
    campaigns_leads: campaignLeadsRelation,
    filters: filtersReducer,
    auth: authReducer,
    reports: reportsReducer,
})