import * as campaignTypes from "./campaignTypes.json";
import * as errorTypes from "./errors.json";

const shortcodes = [
    '[First Name]',
    '[Last Name]',
    '[Job Number]',
    '[Moving From]',
    '[Moving To]',
    '[Job Date]',
    '[Office Name]',
]

const paginationDefaultParams = {
    per_page: 25,
    page: 1,
    order: 'desc'
}

export {
    errorTypes,
    shortcodes,
    campaignTypes,
    paginationDefaultParams,
};