import {GET_ALL_TEMPLATES, TEMPLATES_INIT_STATE} from "./types";
import {
    CUSTOM_LOADING_HIDE,
    CUSTOM_LOADING_SHOW,
    LOADING_HIDE,
    LOADING_SHOW, TABLE_LOADING_HIDE, TABLE_LOADING_SHOW, TEXTAREA_LOADING_HIDE,
    TEXTAREA_LOADING_SHOW
} from "../loading/types";
import {baseAxios, fetchApi} from "../../../../services/api";
import * as configs from "../../../../configs";
import * as templatesMock from "../../../mocks/templates.json";
import axios from "axios";
import {getUrl} from "../../../../services/helper";
import {api} from "../../../../configs";
import {filterG} from "../../../../components/modal/informationModal/contents/leadsFilter/helpers";

export const getAllTemplates = ({
                                    token,
                                    paginationParams = {
                                        per_page: 25,
                                        page: 1,
                                        order: 'desc'
                                    },
                                    showLoading = false,
                                    tableLoading = false
                                }) => async dispatch => {

    if (!token) return null

    showLoading && dispatch({type: LOADING_SHOW})
    tableLoading && dispatch({type: TABLE_LOADING_SHOW})

    const response = await baseAxios(token)
        .get(getUrl(api.templates.index), {params: paginationParams})

    showLoading && dispatch({type: LOADING_HIDE})
    tableLoading && dispatch({type: TABLE_LOADING_HIDE})

    dispatch({type: GET_ALL_TEMPLATES, templates: response.data});
}

export const saveTemplate = (token, name, template, enqueueSnackbar, templates) => async dispatch => {

    const response = await baseAxios(token)
        .post(getUrl(configs.api.templates.index), {name,template})

    enqueueSnackbar( response.data.message ?? '', { variant: (response.data.success ? "default" : "error" )} );

    dispatch({
        type: GET_ALL_TEMPLATES,
        templates: {
            ...templates,
            data: {...templates?.data, data: [...templates?.data.data, response?.data?.data]}
        }
    });
}

export const updateTemplate = (token, template, templates, enqueueSnackbar) => async dispatch => {

    dispatch({type: LOADING_SHOW});

    const response = await baseAxios(token).put(getUrl(configs.api.templates.index) + '/' + template.id, template);

    dispatch({type: LOADING_HIDE});

    enqueueSnackbar( response.data.message ?? '', { variant: (response.data.success ? "default" : "error" )} );

    dispatch({
        type: GET_ALL_TEMPLATES,
        templates: {
            ...templates,
            data: {
                ...templates?.data,
                data: templates?.data.data.map(el => el.id === template.id ? response?.data?.data : el)
            }
        }
    });
}

export const deleteTemple = (changedTemplate, templates, enqueueSnackbar) => async dispatch => {

    const {baseUrl, templates: {index}} = configs.api;

    dispatch({type: LOADING_SHOW});

    const response = await axios.delete(`${baseUrl}${index}/${changedTemplate.id}`);

    dispatch({type: LOADING_HIDE});

    enqueueSnackbar( response.data.message ?? '', { variant: (response.data.success ? "default" : "error" )} );

    if (response.data.success) {
        dispatch({
            type: GET_ALL_TEMPLATES,
            templates: {
                ...templates,
                data: {
                    ...templates?.data,
                    data: templates?.data.data.filter(el => el.id !== changedTemplate.id)
                }
            }
        });
    }
}
export const modifyTemplate = async (token, template, tag = '', dispatch) => {

    dispatch({type: TEXTAREA_LOADING_SHOW});

    const response = await baseAxios(token).post(getUrl( configs.api.templates.modify ),{
        template: template,
        tag: tag || 'friendly'
    })

    dispatch({type: TEXTAREA_LOADING_HIDE});

    return response.data
}