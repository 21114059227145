import React, {useState} from "react";
import {FilterContent, TemplateCreateContent} from "../../components/modal/informationModal/contents";
import {CampaignContent} from "../../components/modal/informationModal/contents/campaignContent";
import {openModal} from "../../business/redux/reducers/modal/actions";
import {useDispatch, useSelector} from "react-redux";
import {GridToolbarColumnsButton} from "@mui/x-data-grid";
import {Box, Container, Grid, Button, Typography, Badge, TextField} from "@mui/material";
import {FilterAltOutlined, ViewWeekOutlined, EmailOutlined, PhoneOutlined, SmsOutlined} from '@mui/icons-material';
import './style.css';
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

export const CustomToolbar = ({open, setOpen, searchText, setSearchText}) => {

    const dispatch = useDispatch();

    const activeButtonClass = open ? " active_create_campaign" : "";

    const {filters} = useSelector(s => s);

    const {leadFilter} = filters;
    // const {logFilter} = filters;

    const campaignTypes = ['call', 'sms', 'email'];

    const campaignTypeIcon = type => type === 'call'
        ? <PhoneOutlined sx={{mr: 1,fontSize: '24px'}}/>
        : type === 'sms'
            ? <SmsOutlined sx={{mr: 1,fontSize: '24px'}}/>
            : type === 'email'
                ? <EmailOutlined sx={{mr: 1,fontSize: '24px'}}/>
                : <></>

    const filterCount = Object.keys(leadFilter)
        .reduce((acc, curr) => {
            return Array.isArray(leadFilter[curr])
                ? acc + leadFilter[curr].length
                : acc + Number(!!leadFilter[curr])
        }, 0)

    return (
        <Container maxWidth="false" style={{backgroundColor: '#EBECF0', padding: '5px 8px'}}>
            <Grid container direction="row" alignItems="center">
                <Grid item container direction="row" xs={9} sx={{p: 0}} alignItems="center">
                    <Container maxWidth="false" style={{backgroundColor: '#EBECF0', padding: '5px 8px'}}>
                        <Grid item container direction="row" xs={3} sx={{p: 1}} alignItems="center">
                            <FormControl className="search_field filters_field" fullWidth>
                                <TextField
                                    value={searchText}
                                    variant="outlined"
                                    placeholder="Serach"
                                    onChange={e => setSearchText(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Container>
                </Grid>
                <Grid item container direction="row" xs={3} sx={{p:0}} alignItems="center" justifyContent="flex-end">
                    {/*<Box display={'flex'} sx={{p:1}} className={`new_campaign_step_style${activeButtonClass}`}>*/}
                    {/*    <Button sx={{mr:0}}*/}
                    {/*            className="Campaign_btn"*/}
                    {/*            onClick={() => dispatch(openModal ({content: <FilterContent/>, style:{width:'780px'}}))}*/}
                    {/*    >*/}
                    {/*        <Badge badgeContent={filterCount} // TODO check this*/}
                    {/*               color="primary"*/}
                    {/*               sx={{mr:2}}*/}
                    {/*               className="filter_count"*/}
                    {/*        >*/}
                    {/*            <FilterAltOutlined sx={{fontSize:'24px'}}/>*/}
                    {/*        </Badge> filters*/}
                    {/*    </Button>*/}
                    {/*    <Typography variant="div" component="span" className="count_campaign_step count_campaign_step_right">*/}
                    {/*        1*/}
                    {/*    </Typography>*/}
                    {/*</Box>*/}
                </Grid>
            </Grid>
        </Container>
    );
}