import React, {Fragment, useEffect, useState} from "react";
import {StepOneContent} from "./stepOne";
import {StepTwoContent} from "./stepTwoContent";
import {NewCampaignCreateHeader} from "./newCampaignCreateHeader";
import {NewCampaignCreateFooter} from "./newCampaignCreateFooter";
import {StepThreeContent} from "./stepThree";
import {initialCampaignCreateState} from "../../../../../screens/assignedLeads/constants";
import {Box} from "@mui/material";
import './style.css';

export const CampaignContent = ({selectedLeads, type}) => {

    const [sent, setSent] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [campaignCreateState, setCampaignCreateState] = useState({
        ...initialCampaignCreateState,
        leads: selectedLeads,
        settings: {
            template: '',
            callDelay: type === 'call' ? 3 : null
        },
        type
    });

    useEffect( ()=> {
        setCampaignCreateState(prev => ({...prev, leads: selectedLeads.map(lead_id => ({lead_id}))}))
    },[selectedLeads.length])

    return (
        <Fragment>

            <NewCampaignCreateHeader type={type} />

            <Box
                className="modal-description"
                direction="row"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    height: 'calc(100% - 132px)'
                }}
            >

                {activeStep === 0 &&
                    <StepOneContent
                        type={type}
                        sent={sent}
                        campaignCreateState={campaignCreateState}
                        setCampaignCreateState={setCampaignCreateState}
                    />}

                {activeStep === 1 && type !== 'call' &&
                    <StepTwoContent
                        type={type}
                        sent={sent}
                        campaignCreateState={campaignCreateState}
                        setCampaignCreateState={setCampaignCreateState}
                    />}

                {(activeStep === 2 || (activeStep === 1 && type === 'call')) &&
                    <StepThreeContent
                        campaignCreateState={campaignCreateState}
                        setCampaignCreateState={setCampaignCreateState}
                    />}

            </Box>

            <NewCampaignCreateFooter
                type={type}
                setSent={setSent}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                campaignCreateState={campaignCreateState}
            />

        </Fragment>
    )
}
