import React from "react";
import {getDateRange} from "../../business/helpers/util";
import {TextField, Container, Grid, FormControl} from "@mui/material";
import {enqueueSnackbar} from "notistack";
import './style.css';

export const CustomToolbar = ({searchDate, setSearchDate}) => {

    const dateChangeHandler = e => {

        const {from, to} = searchDate

        const {name, value} = e.target

        if (name === 'from' && to && !getDateRange(value, to))
            return enqueueSnackbar('The date must be less than the form date', {variant: 'warning'})

        if (name === 'to' && from && !getDateRange(from, value))
            return enqueueSnackbar('The date must be greater than the form date', {variant: 'warning'})

        return setSearchDate({...searchDate, [name]: value})
    }

    return (
        <Container maxWidth="false" style={{backgroundColor: '#EBECF0', padding: '5px 8px'}}>
            <Grid item container direction="row" xs={9} sx={{p: 0}} alignItems="center"
                  style={{backgroundColor: '#EBECF0', padding: '5px 8px'}}>
                <Grid item xs={3} sx={{p: 1}} alignItems="center">
                    <FormControl className="search_field filters_field" fullWidth>
                        <TextField
                            type="date"
                            name="from"
                            value={searchDate?.from || null}
                            variant="outlined"
                            placeholder="Serach"
                            onChange={dateChangeHandler}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} sx={{p: 1}} alignItems="center">
                    <FormControl className="search_field filters_field" fullWidth>
                        <TextField
                            type="date"
                            name="to"
                            value={searchDate?.to || null}
                            variant="outlined"
                            placeholder="Serach"
                            onChange={dateChangeHandler}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </Container>
    );
}