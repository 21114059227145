import React from "react";
import moment from "moment";
import {CampaignActionButtons} from "./campaignActionButtons";
import {TableCell, Grid, TableContainer, Table, TableBody} from "@mui/material";
import {PhoneOutlined} from "@mui/icons-material";
import {StyledTableRow} from "./constant";

export const CallCampaignDetails = ({campaign}) => (
    <TableContainer className='sms_table_style'>
        <Table aria-label="simple table">
            <TableBody>
                <StyledTableRow>
                    <TableCell align="left">Campaign title</TableCell>
                    <TableCell align="left">{campaign.name}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">
                        <Grid container direction="row" alignItems="center">
                            <PhoneOutlined sx={{mr: 1, fontSize: '24px', color: '#007BFF'}}/>
                            Call
                        </Grid>
                    </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Date created</TableCell>
                    <TableCell align="left">{moment(campaign.createdAt).format('ll')}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Leads</TableCell>
                    <TableCell align="left">{campaign.leadsCount}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">{campaign.status}</TableCell>
                </StyledTableRow>

                <CampaignActionButtons campaign={campaign}/>

            </TableBody>
        </Table>
    </TableContainer>
)