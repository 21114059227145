import React from "react";
import {campaignCancel, campaignDelete, campaignPause, startCampaign} from "../../../../../business/redux/reducers/campaign/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button, Stack} from "@mui/material";
import {enqueueSnackbar} from "notistack";

export const CampaignActionButtons = ({campaign}) => {

    const {auth, campaigns} = useSelector(s => s);

    const dispatch = useDispatch();

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            mt={3}
        >
            {Boolean(campaign.status === 'not_started' || campaign.status === 'paused') && Boolean(campaign.leadsCount) &&
                <Button
                    sx={{mx: 2, px: 2}}
                    variant="contained"
                    className="start_campaign_btn"
                    onClick={() => dispatch(startCampaign(auth?.__raw, campaign.id, enqueueSnackbar, campaigns))}
                >
                    {campaign.status === 'not_started' && "Start"}
                    {campaign.status === 'paused' && "Resume"}
                </Button>}

            {Boolean(campaign.status === 'not_started' && campaign.leadsCount) &&
                <Button
                    variant="contained"
                    className="cancel_campaign_btn"
                    sx={{mx: 2, px: 2}}
                    onClick={() => dispatch(campaignPause(auth?.__raw, campaign.id, enqueueSnackbar, campaigns))}
                >
                    Pause Campaign
                </Button>}

            {Boolean(campaign.status === 'paused' || campaign.status === 'not_started') && Boolean(campaign.leadsCount) &&
                <Button
                    variant="contained"
                    className="cancel_campaign_btn"
                    sx={{mx: 2, px: 2}}
                    onClick={() => dispatch(campaignCancel(auth?.__raw, campaign.id, enqueueSnackbar, campaigns))}
                >
                    Stop Campaign
                </Button>}

            <Button
                variant="contained"
                className="cancel_campaign_btn"
                sx={{mx: 2, px: 2}}
                onClick={() => dispatch(campaignDelete(auth?.__raw, campaign.id, campaigns))}
            >
                Delete Campaign
            </Button>
        </Stack>
    )
}