import React, {Fragment, useEffect} from "react";
import {Outlet} from "react-router-dom";
import {HeaderSection} from "../../headerSection";
import {HeaderNavigationSection} from "../../headerNavigationSection";
import {useAuth0} from "@auth0/auth0-react";
import {useDispatch, useSelector} from "react-redux";
import {InformationModal} from "../../modal";
import {setAuth} from "../../../business/redux/reducers/auth/actions";

export const PrivateRoutesLayout = () => {

    const {isAuthenticated, loginWithRedirect, logout, isLoading, getIdTokenClaims} = useAuth0();

    const dispatch = useDispatch();

    useEffect(() => {
        getIdTokenClaims().then(r => r && dispatch(setAuth(r)))
    }, []);

    const {modalData} = useSelector(s => s);

    if (!isLoading && !isAuthenticated) {
        logout().then(() => loginWithRedirect())
    }
    return (
        <>
            {!isLoading && isAuthenticated &&
                <Fragment>
                    <HeaderSection/>
                    <HeaderNavigationSection/>
                    <Outlet/>
                    <InformationModal
                        isOpen={modalData?.isOpen}
                        content={modalData?.content}
                        style={modalData?.style}
                    />
                </Fragment>
            }
        </>
    );
}