import React, {useState} from 'react';
import * as PropTypes from "prop-types";
import {openModal} from "../../business/redux/reducers/modal/actions";
import {ViewCampaignContent} from "../../components/modal/informationModal/contents";
import {callLead} from "../../business/redux/reducers/leads/actions"
import {CreateTemplateContent} from "../../components/modal/informationModal/contents/campaignContent/createTemplateContent";
import {useDispatch, useSelector} from "react-redux";
import {Menu, Box, Button, Link, MenuItem, Typography} from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

export default  function BasicMenu({lead}) {

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null );

    const {auth} = useSelector(s => s);

    const open = Boolean(anchorEl);

    const handleClick = e => setAnchorEl(e.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const sendSmsLeadHandler = () => dispatch(openModal({
        style: {width: '780px'},
        content: <CreateTemplateContent type={'sms'} lead={lead} handleClose={handleClose}/>
    }))

    const sendEmailLeadHandler = () => dispatch(openModal({
        style: {width: '780px'},
        content: <CreateTemplateContent type={'email'} lead={lead} handleClose={handleClose}/>
    }))

    return (
        <div>
            <Button
                id="sub-menu_button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon/>
            </Button>
            <Menu
                id="sub-menu"
                className="sub_menu_lead"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{'aria-labelledby': 'basic-button'}}
            >
                <MenuItem onClick={async () => {
                    await callLead(auth?.__raw, 12)
                    handleClose();
                }}><Button variant="outlined" startIcon={<PhoneOutlinedIcon/>}>Call now</Button></MenuItem>

                <MenuItem onClick={sendSmsLeadHandler}><Button variant="outlined" startIcon={<SmsOutlinedIcon/>}>Send SMS</Button></MenuItem>

                <MenuItem onClick={sendEmailLeadHandler}><Button variant="outlined" startIcon={<EmailOutlinedIcon/>}>Send email</Button></MenuItem>
            </Menu>
        </div>
    );
}

function Div(props) {
    return null;
}

Div.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};
const columns = dispatch => ([

    {
        field: 'Job_Number',
        headerName: 'Job Number',
        type: 'string',
        flex:0.8,
        renderCell: v => (
            <Link
                target="_blank"
                underline="hover"
                className="job_number"
                href={`https://crm.zoho.com/crm/org692830833/tab/Leads/${v.row.zoho_id}`}
            >
                {v.formattedValue}
            </Link>
        )
    },
    {
        field: 'first_name',
        headerName: 'First Name',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Typography variant="div" component="div" className="first_name">
                {v.row.first_name}
            </Typography>
        )
    },
    {
        field: 'last_name',
        headerName: 'Last Name',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Typography variant="div" component="div" className="last_name">
                {v.row.last_name}
            </Typography>
        )
    },
    {
        field: 'moving_from',
        headerName: 'Moving From',
        type: 'string',
        flex:1,
        renderCell: (v) => (
            <Typography variant="div" component="div" className="movng_from">
                {v.row.moving_from}
            </Typography>
        )
    },
    {
        field: 'moving_to',
        headerName: 'Moving To',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Typography variant="div" component="div" className="movng_to">
                {v.row.moving_to}
            </Typography>
        )
    },
    {
        field: 'estimated_price',
        headerName: 'Price',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Typography variant="div" component="div" className="estimated_price">
                {v.row.estimated_price}
            </Typography>
        )
    },
    {
        field: 'Lead_Stage',
        headerName: 'Stage',
        type: 'string',
        flex:1,
        renderCell: v => (
            // class colors- green_text,orange_text,red_text
            <Typography
                variant="div"
                component="div"
                className={
                    v.row.Lead_Stage === 'New Lead'
                        ? 'green_text'
                        : v.row.Lead_Stage === '1st Attempt'
                            ? 'red_text'
                            :''
                }
            >
                {v.row.Lead_Stage}
            </Typography>
        )
    },
    {
        field: 'move_Type',
        headerName: 'Move Type',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Typography variant="div" component="div" className="move_type">
                {v.row.move_Type}
            </Typography>
        )
    },
    {
        field: 'phone',
        headerName: 'Lead Phone',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Typography variant="div" component="div" className="lead_phone">
                {v.row.phone}
            </Typography>
        )
    },
    {
        field: 'email',
        headerName: 'Email',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Typography variant="div" component="div" className="email">
                {v.row.email}
            </Typography>
        )
    },
    {
        field: 'job_date',
        headerName: 'Job Date',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Box>
                { moment(v.row.job_date).format("YYYY/MM/DD") }
                <div className={"days_ago_sub"}>
                    { moment(v.row.job_date).fromNow() }
                </div>
            </Box>
        )
    },
    {
        field: 'createdAt',
        headerName: 'Last Communication',
        type: 'string',
        flex:1,
        renderCell: v => (
            <Box>
                {v.row.last_communication}{v.row.last_communication && <div
                className={"days_ago_sub"}>{moment(v.row.last_communication).fromNow()}</div>}
                {/*<Link component="button" underline="none" className="view_btn_style">*/}
                {/*    View*/}
                {/*</Link>*/}
            </Box>
        )
    },
    {
        field: 'Campaigns',
        sortable: false,
        flex:0.6,
        renderCell: v => (
            <Box>
                <Typography variant="div" component="div" className="count_compaings">
                    {v.row.campaigns.length || 'N/A'}
                </Typography>
                {!!v.row.campaigns.length &&
                    <Link component="button" underline="none" className="view_btn_style" onClick={() => dispatch(
                        openModal({
                            content: <ViewCampaignContent lead={v.row}/>,
                            style: {maxWidth: '1130px', width: 'inherit'}
                        })
                    )}>
                        View
                    </Link>}
            </Box>
        )
    },
    {

        field: " ",
        sortable: false,
        disableColumnMenu:true,
        flex:0.5,
        renderCell: v => <BasicMenu lead={v.row}/>
    }
]);
const initialCampaignCreateState = {
    leads: [],
    name: '',
    // started_date: dayjs(),
    type: 'sms',
    settings: {
        callDelay: 3,
        template: '',
    },
    userId: 4064759000069945023,
}

export {
    columns,
    initialCampaignCreateState,
}