import * as React from 'react';
import Typography from "@mui/material/Typography";
import {useAuth0} from "@auth0/auth0-react";

type Props = {

};

export const ErrorComponent = (props: Props) => {

    const {loginWithRedirect} = useAuth0();

    return (
        <Typography variant={'h1'} justify={'center'} align={'center'}>
            <br/><br/><br/><br/>
            <h1>NOT AUTHENTICATED USER</h1><br/><br/><br/>
            <h1>PLEASE <button onClick={() => loginWithRedirect()}>Log In</button></h1>
        </Typography>
    );
};