import React, {useState} from "react";
import {closeModal} from "../../../../../business/redux/reducers/modal/actions";
import {useDispatch} from "react-redux";
import {TabPanel} from "../../../../tabPanel/tabPanel";
import {a11yProps} from "../../../../tabPanel/functionHelpers";
import {SmsCampaignDetails} from "./smsCampaignDetails";
import {CallCampaignDetails} from "./callCampaignDetails";
import {EmailCampaignDetails} from "./emailCampaignDetails";
import {DataGrid} from "@mui/x-data-grid";
import {HighlightOff} from "@mui/icons-material";
import {Box, Button, Container, Tab, Tabs, Typography} from "@mui/material";
import {assignedLeadsColumns} from "../../../../../screens/campaigns/columns";

export const CampaignDetails = ({campaign}) => {

    const dispatch = useDispatch();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => setValue(newValue);

    return (
        <>
            <Box className="modal-title" sx={{p:3}} direction="row" display="flex" justifyContent="space-between">
                <Typography  variant="h1" component="div" fontWeight="700" color="#2B3033">
                     Campaign {campaign.name}
                </Typography>
                <Button onClick={() => dispatch( closeModal() )} variant="text" sx={{py:0, minWidth:'unset'}}>
                    <HighlightOff sx={{ color: '#2B3033',fontSize:'22px'}}/>
                </Button>
            </Box>
            <Box className="modal-description" direction="row" sx={{flexGrow: 1, bgcolor: 'background.paper', height: 'calc(100% - 78px)'}}>
                <Box sx={{ width: '100%'}}>1
                    <Box sx={{ borderTop: 1, borderColor: '#fff',bgcolor:"#F4F5F8" }}>
                        <Tabs className="tabs_btn_content" value={value} onChange={handleChange} aria-label="basic tabs example"  variant="fullWidth">
                            <Tab label="campaign details" className="tab_btn" {...a11yProps(0)} />
                            <Tab label={`leads assigned to campaign (${campaign?.leadsCount})`} className="tab_btn" {...a11yProps(1)} />
                        </Tabs>
                    </Box>

                    <TabPanel value={value} index={0}>
                        {campaign.type === 'call' && <CallCampaignDetails campaign={campaign}/>}
                        {campaign.type === 'sms' && <SmsCampaignDetails campaign={campaign}/>}
                        {campaign.type === 'email' && <EmailCampaignDetails campaign={campaign}/>}
                    </TabPanel>

                    <TabPanel value={value} index={1}>
                        <Container sx={{padding:'20px'}}>

                            <Box sx={{width: '100%'}}>
                                <DataGrid
                                    rows={campaign?.assigned_leads || []}
                                    columns={assignedLeadsColumns}
                                />
                            </Box>
                        </Container>
                    </TabPanel>
                </Box>
            </Box>

        </>
    )
}