import React from "react";
import {useNavigate} from 'react-router-dom';
import {Filter} from "./components";
import {Button, Container, Grid} from "@mui/material";
import {ASSIGNED_LEADS_ROUTE} from "../../business/routes/routes";
import {Add} from "@mui/icons-material";

export const CampaignToolbar = ({filterParams, setFilterParams}) => {

    const navigate = useNavigate();

    return (
        <Container maxWidth="false" style={{backgroundColor: '#EBECF0', padding: '5px 8px'}}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">

                <Filter filterParams={filterParams} setFilterParams={setFilterParams}/>

                <Grid item container direction="row" xs={3} sx={{p: 1}} alignItems="center" justifyContent="flex-end">
                    <Button
                        sx={{mr: 1}}
                        className="Campaign_btn"
                        onClick={() => navigate(ASSIGNED_LEADS_ROUTE.path, {
                            state: {
                                prevUrl: ASSIGNED_LEADS_ROUTE.path,
                                actionType: 'create_campaign'
                            }}) }
                    >
                        <Add sx={{mr: 1, fontSize: '24px', color: '#007BFF'}}/> new campaign
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}
