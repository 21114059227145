import React, {useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {EditTemplateForm} from "./childModalContents/editTemplateForm";
import {ChildModal} from "../../../childModal";
import {closeModal} from "../../../../../business/redux/reducers/modal/actions";
import {StyledTableRow, styleTitle} from "../../../../../screens/templates/styles";
import {Box, Button, Chip, Container, Grid, Icon, Stack, Table, TableBody, TableContainer, Typography, TableCell} from "@mui/material";
import EdiText from 'react-editext';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import moment from "moment";
import {updateTemplate} from "../../../../../business/redux/reducers/template/actions";
import {enqueueSnackbar} from "notistack";
import {TemplateDeleteContent} from "./childModalContents/templateDeleteContent";
import {shortcodes} from "../../../../../business/constants";


export const TemplateDetailsContent = ({template}) => {

    const textAreaRef = useRef('');
    const dispatch = useDispatch();

    const [changedTemplate, setChangedTemplate] = useState(template);
    const [editTemplate, setEditTemplate] = useState(false);
    const [modalData, setModalData] = useState({open: false, content: <></>});

    const {templates, auth} = useSelector(s => s);

    const saveHandler = () => {
        dispatch(updateTemplate(auth?.__raw, changedTemplate, templates, enqueueSnackbar))
        dispatch(closeModal())
    }

    return (
        <>
            <Box className="modal-title" sx={{p: 3}} direction="row" display="flex" justifyContent="space-between">
                <Typography variant="h1" component="div" fontWeight="700" color="#2B3033">
                    <Icon
                        sx={{color: '#007BFF', mr: 1, fontSize: '24px'}}
                        className="fa fa-stamp"
                    /> Template details
                </Typography>
                <Button onClick={() => dispatch(closeModal())} variant="text" sx={{py: 0, minWidth: 'unset'}}>
                    <HighlightOffIcon sx={{color: '#2B3033', fontSize: '22px'}}/>
                </Button>
            </Box>
            <Box className="modal-description" direction="row"
                 sx={{flexGrow: 1, bgcolor: 'background.paper', height: 'calc(100% - 132px)'}}>
                <Grid sx={{width: '530px'}}>
                    <Container sx={{padding: '0px !important'}}>
                        <Box direction="row" fullWidth>
                            <TableContainer className='sms_table_style'>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <StyledTableRow>
                                            <TableCell align="left">Template name</TableCell>
                                            <TableCell align="left">{changedTemplate?.name}</TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    variant="text"
                                                    onClick={() => setModalData({
                                                        open: true,
                                                        content: <EditTemplateForm
                                                            changedTemplate={changedTemplate}
                                                            setChangedTemplate={setChangedTemplate}
                                                            setModalData={setModalData}
                                                            styleTitle={styleTitle}
                                                        />
                                                    })}
                                                >
                                                    Edit
                                                </Button>
                                            </TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell align="left">Date created</TableCell>
                                            <TableCell align="left">
                                                {moment(changedTemplate.created_at).format('ll')}
                                            </TableCell>
                                            <TableCell align="right"></TableCell>
                                        </StyledTableRow>
                                        <StyledTableRow>
                                            <TableCell align="left">Times used</TableCell>
                                            <TableCell align="left">7</TableCell>
                                            <TableCell align="right"></TableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                        <Box direction="row" alignItems="start" sx={{padding: '20px'}} fullWidth>
                            <EdiText
                                ref={textAreaRef}
                                viewContainerClassName='my-custom-view-wrapper'
                                type='textarea'
                                editButtonContent='Edit template content'
                                onEditingStart={(e) => setEditTemplate(!editTemplate)}
                                onCancel={() => setEditTemplate(!editTemplate)}
                                saveButtonContent="Apply"
                                cancelButtonContent={<strong>Cancel</strong>}
                                inputProps={{
                                    className: 'textarea_campaign_temp',
                                    placeholder: 'Enter campaign text here',
                                    style: {outline: 'none', minWidth: '10%'},
                                    onChange: e => setChangedTemplate(prev => ({...prev, template: e.target.value}))
                                }}
                                value={changedTemplate.template}
                                onSave={template => {
                                    setChangedTemplate(prev => ({...prev, template }))
                                    setEditTemplate(!editTemplate)
                                }}
                                renderValue={(value) => {
                                    const regexp = /(?=\[)\[[A-Za-z ]+\](?<=\])/g
                                    const valueWithParsedURLS = value.replace(
                                        regexp,
                                        "<b>$&</b>"
                                    ).replace(/(?:\r\n|\r|\n)/g, '<br>');
                                    return (
                                        <p className="view_templ_cont"
                                           dangerouslySetInnerHTML={{__html: valueWithParsedURLS}}
                                        />
                                    )
                                }}
                            />
                            <Button
                                sx={{textTransform: 'capitalize', mt: -8}}
                                variant="text"
                                className="red_text del_btn"
                                onClick={() => setModalData({
                                    open: true,
                                    content: <TemplateDeleteContent
                                        changedTemplate={changedTemplate}
                                        setModalData={setModalData}
                                        styleTitle={styleTitle}
                                    />
                                })}
                            >
                                Delete template
                            </Button>
                        </Box>
                    </Container>
                </Grid>

                {editTemplate &&
                    <Grid sx={{width: '250px'}} bgcolor="#EBECF0" height="100%">
                        <Container sx={{padding: '20px'}}>
                            <Typography variant="body2" component="div" fontWeight="500" color="#2B3033" mb={3}>
                                Insert shortcode
                            </Typography>
                            <Box justifyContent="start">
                                {shortcodes.map(t =>
                                    <Chip
                                        key={t}
                                        className="chip_shortcode"
                                        label={t}
                                        onClick={() => setChangedTemplate(prev => ({
                                            ...prev,
                                            template: `${prev.template} ${t}`
                                        }))}
                                        clickable
                                    />
                                )}
                            </Box>
                        </Container>
                    </Grid>
                }
            </Box>

            <Stack className="modal-footer" sx={{py: '10px', px: 2}} display="flex" direction="row"
                   justifyContent="space-between">
                <Button onClick={() => dispatch(closeModal())} variant="contained"
                        className="cancle_modal_btn">Cancel</Button>
                <Button
                    variant="contained"
                    className="view_results_btn active"
                    onClick={saveHandler}
                >
                    Save changes
                </Button>
            </Stack>

            <ChildModal
                open={modalData.open}
                setOpen={isOpen => setModalData({...modalData, open: isOpen})}
                modalContent={modalData.content}
                // modalContent={<TemplateDeleteContent setModalData={setModalData} styleTitle={styleTitle} />}
            />
        </>
    )
}