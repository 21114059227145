import {GET_AUTH_SUCCESS, GET_AUTH_FAILURE, CLEAN_AUTH, AUTH_INIT_STATE} from "./types";

export const authReducer = (state = AUTH_INIT_STATE, action) => {
    switch (action.type) {
        case GET_AUTH_SUCCESS:
            return action?.auth;
        case GET_AUTH_FAILURE:
            return action?.auth;
        case CLEAN_AUTH:
            return AUTH_INIT_STATE;
        default:
            return state
    }
}
// When called with an action of type "GET_AUTH_SUCCESS", the slice reducer for key "auth" returned undefined. To ignore an action, you must explicitly return the previous state. If you want this reducer to hold no value, you can return null instead of undefined.
//     at combination (http://localhost:3000/static/js/bundle.js:162452:15)
// at dispatch (http://localhost:3000/static/js/bundle.js:162210:22)
// at http://localhost:3000/static/js/bundle.js:161921:16
//     at dispatch (http://localhost:3000/static/js/bundle.js:162564:28)
// at http://localhost:3000/static/js/bundle.js:540:12
//     at http://localhost:3000/static/js/bundle.j