import {GET_CMP_LEADS_RLS_SUCCESS,GET_CMP_LEADS_RLS_FAILURE, CLEAN_CMP_LEADS_RLS, CMP_LEADS_RLS_INIT_STATE} from "./types";

export const campaignLeadsRelation = (state = CMP_LEADS_RLS_INIT_STATE, action) => {

    switch (action.type) {
        case GET_CMP_LEADS_RLS_SUCCESS:
            return action.campaigns_leads;
        case GET_CMP_LEADS_RLS_FAILURE:
            return action.campaigns_leads;
        case CLEAN_CMP_LEADS_RLS:
            return CMP_LEADS_RLS_INIT_STATE;
        default:
            return state
    }
}