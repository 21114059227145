import React, {Fragment, useState} from "react";
import {StepTwoContent} from "./stepTwoContent";
import {directSmsOrEmail} from "../../../../../business/redux/reducers/leads/actions";
import {Box, Button, Stack} from "@mui/material";
import {initialCampaignCreateState} from "../viewCampaignContent/constants";
import {useDispatch, useSelector} from "react-redux";


export const CreateTemplateContent = ({type, lead, handleClose}) => {

    const dispatch = useDispatch();

    const [sent, setSent] = useState(false)

    const {auth, loading} = useSelector(s => s);

    const [campaignCreateState, setCampaignCreateState] = useState({...initialCampaignCreateState, type, subject: ''});

    const handleSubmit = async () => {
        if (!campaignCreateState?.settings.template || !campaignCreateState?.subject) {
            setSent(true)
        } else {
            dispatch(directSmsOrEmail({
                token: auth?.__raw,
                zoho_id: lead?.zoho_id,
                template: campaignCreateState.settings.template,
                subject: campaignCreateState.subject,
                type
            }));
            handleClose();
        }
    };

    return (
        <Fragment>
            <Box
                className="modal-description"
                direction="row"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.paper',
                    height: 'calc(100% - 132px)'
                }}
            >

                <StepTwoContent
                    type={type}
                    sent={sent}
                    campaignCreateState={campaignCreateState}
                    setCampaignCreateState={setCampaignCreateState}
                />
            </Box>

            <Stack className="modal-footer" sx={{py: '10px', px: 2}} display="flex" direction="row" justifyContent="space-between">
                <Button
                    variant="contained"
                    className="view_results_btn active"
                    disabled={loading.isLoading}
                    onClick={handleSubmit}>
                    Submit
                </Button>
            </Stack>
        </Fragment>
    )
}
