import * as React from 'react';
import {Link} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {openModal} from "../../business/redux/reducers/modal/actions";
import {TemplateDetailsContent} from "../../components/modal/informationModal/contents/templateDetailsContent";
import moment from "moment";

const viewEditHandler = (dispatch) => cellValues => (
    <Box textAlign="center" width='100%'>
        <Link component="button" underline="none" className="view_btn_style" onClick={() => dispatch(
            openModal({
                style: {maxWidth: '780px', width: 'inherit'},
                content: <TemplateDetailsContent template={cellValues.row}/>
            })
        )}>
            View / Edit
        </Link>
    </Box>
);


const columns = dispatch => ([
    {
        field: 'name',
        headerName: 'Template Name',
        type: 'string',
        flex:1,
        renderCell: (cellValues) => {
            return (
                <Typography variant="div" component="div" className="last_name">
                    {cellValues.row.name}
                </Typography>
            );
        }
    },
    {
        field: 'template',
        headerName: 'Excerpt',
        type: 'string',
        flex:1.9,
    },
    {
        field: 'created_at',
        headerName: 'Date Created',
        type: 'string',
        flex:1,
        maxWidth:150,
        minWidth:150,
        renderCell: (cellValues) => {
            return (
                <Box>
                    {moment(cellValues.row.created_at).format('ll')}
                </Box>
            );
        }
    },
    {
        field: 'times_used',
        headerName: 'Times Used',
        type: 'string',
        flex:0.7,
        renderCell: (cellValues) => {
            return (
                <Box>
                    {cellValues.row.times_used}
                </Box>
            );
        }
    },
    {
        field: ' ',
        sortable: false,
        disableColumnMenu:true,
        flex:0.5,
        renderCell: viewEditHandler(dispatch)
    }
]);
const initialCampaignCreateState = {
    leads: [],
    name: '',
    type: 'sms',
    settings: { template: '' },
    userId: 4064759000069945023,
}

export {
    columns,
    initialCampaignCreateState,
}
