import React from "react";
import {Checkbox, FormControlLabel, IconButton, Box} from "@mui/material";
import {Check} from "@mui/icons-material";

export const CheckBoxGroup = ({
                                  params,
                                  changeHandler = (key, value) => () => {},
                                  allClickHandler,
                                  hasAllButton
                              }) => {

    const checkedIcon = (<IconButton><Check sx={{color: '#007BFF'}}/></IconButton>)

    const allButtonChecked = Object.values(params).filter(el => el.checked).length

    const allTypeChangeObject = () => {

        const data = JSON.parse(JSON.stringify(params || {}))

        const allChecked = allButtonChecked === Object.values(params).length

        Object.keys(data).forEach(el => data[el].checked = !allChecked)

        return data
    }

    return (
        <Box container direction="row" mx={2}>

            {hasAllButton &&
                <FormControlLabel
                    sx={{pr: 2}}
                    key="all"
                    className="label_checkbox_filter"
                    label="All"
                    control={
                        <Checkbox
                            checked={allButtonChecked === Object.values(params).length}
                            checkedIcon={checkedIcon}
                            indeterminate={!(allButtonChecked === 0 || allButtonChecked === Object.values(params).length)}
                            icon={<IconButton/>}
                            onChange={allClickHandler(allTypeChangeObject())}
                        />}
                />}

            {Object.entries(params).map(([key, value]) => (
                <FormControlLabel
                    sx={{pr: 2}}
                    key={key}
                    className="label_checkbox_filter"
                    label={value.name}
                    control={
                        <Checkbox
                            checked={value.checked}
                            checkedIcon={checkedIcon}
                            icon={<IconButton/>}
                            onChange={changeHandler(key, value)}
                        />}
                />))}

        </Box>
    )
}