import {Button, Link} from "@mui/material";
import Box from "@mui/material/Box";
import {openModal} from "../../business/redux/reducers/modal/actions";
import * as React from "react";
import Typography from "@mui/material/Typography";
import {CampaignDetails} from "../../components/modal/informationModal/contents/campaignDetails/campaignDetails";

const columns = dispatch => ([

    {
        field: 'name',
        headerName: 'Campaign Title',
        type: 'string',
        flex:1,
    },
    {
        field: 'type',
        headerName: 'Type',
        type: 'string',
        flex:0.5,
        renderCell: v => (
            <Typography variant="div" component="div" fontWeight="700" textTransform="uppercase">
                {v.row.type}
            </Typography>
        )
    },
    {
        field: 'createdAt',
        headerName: 'Date Created',
        type: 'string',
        flex:0.5,
        maxWidth:150,
        minWidth:150,
        renderCell: v => <Box>{v.row.createdAt}</Box>
    },
    {
        field: 'started_date',
        headerName: 'Start Date / Time',
        type: 'string',
        flex:0.5,
        maxWidth:150,
        minWidth:150,
        renderCell: v => <Box>{v.row.startedDate}</Box>
    },
    {
        field: 'total_leads',
        headerName: 'Total Leads',
        type: 'string',
        flex:1,
        renderCell: v => <Box>{v.row.leadsCount}</Box>
    },
    {
        field: 'status',
        headerName: 'Status',
        type: 'string',
        flex:1,
        renderCell: v => <Box>{v.row.status}</Box>
    },
    {
        field: 'template_used',
        headerName: 'Template Used',
        type: 'string',
        flex:1.5,
        renderCell: v => <Box>{v.row.settings?.template || ''}</Box>
    },
    // {
    //     field: 'success_rate',
    //     headerName: 'Success Rate',
    //     type: 'string',
    //     flex:1,
    //     renderCell: v => (
    //         <Typography
    //             variant="div"
    //             component="div"
    //             className={
    //                 v.row.success_rate >= 50
    //                     ? 'orange_text'
    //                     : v.row.success_rate <= 20
    //                         ? 'red_text'
    //                         : 'green_text'
    //             }>
    //             {v.row.success_rate || 100}%
    //         </Typography>
    //     )
    // },
    {
        field: ' ',
        sortable: false,
        disableColumnMenu:true,
        flex:0.5,
        renderCell: v => {
            return (
                <Box textAlign="center" width='100%'>
                    <Link component="button" underline="none" className="view_btn_style" onClick={() => dispatch(
                        openModal({content: <CampaignDetails campaign={v.row}/>})
                    )}>
                        View
                    </Link>
                </Box>
            );
        }
    }
]);



// lead stage
const assignedLeadsColumns = [
    {
        field: 'Last_Name',
        headerName: 'Name',
        type: 'string',
        width: 100,
    },
    {
        field: 'Job_Number',
        headerName: 'Job Number',
        type: 'string',
        width: 100,
        renderCell: v => (
            <Link
                target="_blank"
                underline="hover"
                className="job_number"
                href={`https://crm.zoho.com/crm/org692830833/tab/Leads/${v.row.zoho_id}`}
            >
                {v.formattedValue}
            </Link>
        )
    },
    {
        field: 'Job_Date',
        headerName: 'Job Date',
        type: 'string',
        width: 200,
    },
    {
        field: 'OfficeTXT',
        headerName: 'Office Text',
        type: 'string',
        width: 150,
    },
    {
        field: 'Lead_Stage',
        headerName: 'Lead Stage',
        type: 'string',
        width: 150,
    }
];


const initialCampaignCreateState = {
    leads: [],
    name: '',
    type: 'sms',
    settings: { template: '' },
    userId: 4064759000069945023,
}

export {
    columns,
    assignedLeadsColumns,
    initialCampaignCreateState,
}