import React from "react";
import {CampaignActionButtons} from "./campaignActionButtons";
import {TableCell, TableContainer, Table, TableBody, Grid} from "@mui/material";
import {EmailOutlined} from "@mui/icons-material";
import {StyledTableRow} from "./constant"

export const EmailCampaignDetails = ({campaign}) => (
    <TableContainer className='sms_table_style'>
        <Table aria-label="simple table">
            <TableBody>
                <StyledTableRow>
                    <TableCell align="left">Campaign title</TableCell>
                    <TableCell align="left">{campaign.name}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Type</TableCell>
                    <TableCell align="left">
                        <Grid container direction="row" alignItems="center">
                            <EmailOutlined sx={{mr: 1, fontSize: '24px', color: '#007BFF'}}/> Email
                        </Grid>
                    </TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Date created</TableCell>
                    <TableCell align="left">{campaign.createdAt}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Start date and time</TableCell>
                    <TableCell align="left">{campaign.startedDate}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Leads</TableCell>
                    <TableCell align="left">{campaign.leadsCount}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Template used</TableCell>
                    <TableCell align="left">{campaign?.settings?.template || ""}</TableCell>
                </StyledTableRow>
                <StyledTableRow>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">{campaign.status}</TableCell>
                </StyledTableRow>

                <CampaignActionButtons campaign={campaign}/>

            </TableBody>
        </Table>
    </TableContainer>
);