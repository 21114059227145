import React from "react";
import {deleteTemple} from "../../../../../../business/redux/reducers/template/actions";
import {closeModal} from "../../../../../../business/redux/reducers/modal/actions";
import {useDispatch, useSelector} from "react-redux";
import {Button, Typography, Stack, Box} from "@mui/material";
import {enqueueSnackbar} from "notistack";

export const TemplateDeleteContent = ({changedTemplate, setModalData, styleTitle}) => {

    const dispatch = useDispatch();

    const {templates} = useSelector(s => s);

    const deleteTempleHandler = () => {
        dispatch(deleteTemple(changedTemplate, templates, enqueueSnackbar))
        dispatch(closeModal())
    }


    return (
        <Box sx={styleTitle}>
            <Typography sx={{mb: 3}} variant="h2" component="div" fontWeight="600" color="#2B3033">
                Delete template?
            </Typography>
            <Typography sx={{mb: 0}} variant="div" component="div" fontWeight="400" color="#2B3033">
                You are about to delete template
            </Typography>
            <Typography sx={{mb: 5}} variant="div" component="div" fontWeight="700" color="#2B3033">
                "Follow up template (4)"
            </Typography>
            <Stack display="flex" direction="row" justifyContent="space-between">
                <Button
                    variant="contained"
                    className="cancle_childModal_btn"
                    onClick={() => setModalData(prev => ({...prev, open: false}))}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    className="view_results_btn active"
                    onClick={deleteTempleHandler}
                >
                    Delete template
                </Button>
            </Stack>
        </Box>
    )
}