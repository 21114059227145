const getFilter = filters => {
    const newFilter = {};

    (filters || [])?.map( filter => {
        if (filter.type === 'checkbox'){
            newFilter[ filter.fieldName ] = []
        }
        else if ( filter?.type?.includes('range') ){
            newFilter[ filter?.fieldName + '_from'] = ''
            newFilter[ filter?.fieldName + '_to'] = ''
        }
        else {
            newFilter[ filter.fieldName ] = ''
        }
    })
    return newFilter;
}
const filterG = filter => {
    if (!filter) return null;
    const newFilter = {...filter}
    Object.keys(newFilter)?.map(key => {
        if (!newFilter[key]) {
            delete newFilter[key]
        } else if (newFilter[key].constructor === Array) {
            if (!newFilter[key].length) {
                delete newFilter[key]
            }
        } else if (['from', 'to'].includes(key?.split('_')[key.split('_').length - 1]) && typeof filter[key] === "number") {
            newFilter[key] = newFilter[key]
        } else if (['from', 'to'].includes(key?.split('_')[key.split('_').length - 1])) {
            newFilter[key] = newFilter[key].format()
        }
    })
    return newFilter
}

export {
    getFilter,
    filterG
}

