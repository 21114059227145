import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import {Button, Grid, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DemoContainer, DemoItem} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {StaticTimePicker, TimePicker} from "@mui/x-date-pickers";
import React, {useEffect, useState} from "react";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {CalendarMonthOutlined} from "@mui/icons-material";
import dayjs from "dayjs";

export const DateTimeFields= ({ campaignCreateState, setCampaignCreateState, dateEditable }) =>{

    const [customDate, setCustomDate] = useState(false);

    const [timeZone, setTimeZone] = useState('EST');
    const timeZones = ['EST', 'CST', 'MST', 'PST', 'AHST', 'AKST', 'YST'];

    return(
        <>
            <FormControl sx={{mb: 0}}>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                >
                    <FormControlLabel
                        className="label_checkbox_filter"
                        checked={!customDate}
                        onClick={() => setCustomDate(false)}
                        control={<Radio/>}
                        label="campaign starts right away"
                    />
                    <FormControlLabel
                        className="label_checkbox_filter"
                        checked={customDate}
                        onClick={() => setCustomDate(true)}
                        control={<Radio/>}
                        label="select start date and time"
                    />
                </RadioGroup>
            </FormControl>

            {customDate &&
                <Box className="date_time_change">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoItem label={!dateEditable ? "Select the start date": 'Start date'}>
                            <DemoContainer components={['DatePicker']}
                                           sx={{alignItems: 'center', pt: '0', mb: 3}}>
                                <DatePicker
                                    sx={{mt: 1}}
                                    className="date_picker_field"
                                    value={campaignCreateState?.started_date || null}
                                    onChange={e => setCampaignCreateState( prev => ({
                                        ...prev,
                                        started_date: e
                                    }))}
                                    label="Date"
                                    format="DD, MMMM, YYYY"
                                    components={{
                                        OpenPickerIcon: CalendarMonthOutlined

                                    }}
                                />
                            </DemoContainer>
                        </DemoItem>

                        <DemoItem label={!dateEditable ? "Select the start time" : "Start time"}>
                            <DemoContainer components={['TimePicker', 'TimePicker']}
                                           sx={{alignItems: 'center', pt: '0'}}>
                                <TimePicker
                                    value={campaignCreateState?.started_date || null}
                                    onChange={e => setCampaignCreateState( prev => ({
                                        ...prev,
                                        started_date: e
                                    }))}
                                    views={['hours']}
                                    className="date_picker_field"
                                    label="Hour"
                                    format="hh"
                                />
                                <Typography variant="body1" component="div">:</Typography>
                                <TimePicker
                                    value={campaignCreateState?.started_date || null}
                                    onChange={e => setCampaignCreateState( prev => ({
                                        ...prev,
                                        started_date: e
                                    }))}
                                    views={['minutes']}
                                    className="date_picker_field"
                                    label="Minutes"
                                    format="mm"
                                />
                                <StaticTimePicker
                                    value={campaignCreateState?.started_date || null}
                                    onChange={e => setCampaignCreateState( prev => ({
                                        ...prev,
                                        started_date: e
                                    }))}
                                    views={['hours']}
                                    className="date_picker_amPm"
                                    label="Hour"
                                />
                            </DemoContainer>
                        </DemoItem>
                    </LocalizationProvider>
                    {/*<DemoItem label="Time zone" className="label_input" sx={{mt:2}}>*/}
                    {/*    <Box display="flex" flexWrap="wrap" sx={{pr:10}}>*/}
                    {/*        {timeZones.map((zone,key) => (*/}
                    {/*            <Button*/}
                    {/*                key={key}*/}
                    {/*                sx={{mb: 1, mr: 1}}*/}
                    {/*                variant="text"*/}
                    {/*                className={*/}
                    {/*                    zone === timeZone*/}
                    {/*                        ? "chip_filter_btn chip_active"*/}
                    {/*                        : "chip_filter_btn"*/}
                    {/*                }*/}
                    {/*                onClick={ () => setTimeZone( zone ) }*/}
                    {/*            >*/}
                    {/*                {zone}*/}
                    {/*            </Button>*/}
                    {/*        ))}*/}
                    {/*    </Box>*/}
                    {/*</DemoItem>*/}
                </Box>
            }
        </>
    )
}
