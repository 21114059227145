import React from "react";
import {closeModal} from "../../../../../business/redux/reducers/modal/actions";
import {campaignCreate} from "../../../../../business/redux/reducers/campaign/actions";
import {filterG} from "../leadsFilter/helpers";
import {useDispatch, useSelector} from "react-redux";
import {Button, Stack, Step, StepLabel, Stepper} from "@mui/material";
import {enqueueSnackbar} from "notistack";

export const NewCampaignCreateFooter = ({type, activeStep, setActiveStep, campaignCreateState, setSent}) => {

    const dispatch = useDispatch();

    const {filters, auth, loading} = useSelector(s => s);

    const {leadFilter} = filters;

    const data= campaignCreateState.started_date
        ? {
            ...campaignCreateState,
            settings: campaignCreateState.type === 'call' ? ({
                filterParams: filterG(leadFilter),
                callDelay: campaignCreateState.settings.callDelay
            }) : {...campaignCreateState.settings, filterParams: filterG(leadFilter)},
            started_date: campaignCreateState.started_date.add(1, "m").format(),
        } : {
            ...campaignCreateState,
            settings: campaignCreateState.type === 'call' ? ({
                filterParams: filterG(leadFilter),
                callDelay: campaignCreateState.settings.callDelay
            }) : {...campaignCreateState.settings, filterParams: filterG(leadFilter)},
        }

    const handleSubmit = () => dispatch(campaignCreate(
        auth?.__raw,
        data,
        enqueueSnackbar,
        true
    ));

    const changeStep = count => () => setActiveStep(prevState => prevState + count);

    const clickHandler = (activeStep === 2 || (activeStep === 1 && type === 'call'))
        ? handleSubmit
        : ((activeStep === 0 && !campaignCreateState?.name) || (activeStep === 1 && type === 'email' && !campaignCreateState?.subject))
            ? () => setSent(true)
            : changeStep(1);

    return (
        <Stack className="modal-footer" sx={{py: '10px', px: 2}} display="flex" direction="row"
               justifyContent="space-between">
            {activeStep === 0 &&
                <Button onClick={() => dispatch(closeModal())} variant="contained"
                        className="cancle_modal_btn">Cancel</Button>
            }
            {activeStep !== 0 &&
                <Button className="cancle_modal_btn" onClick={changeStep(-1)} sx={{mr: 1}}>
                    Back
                </Button>}
            <Stepper activeStep={activeStep} variant="outlined">
                {new Array(type === 'call' ? 2 : 3).fill('').map((_, idx) => <Step key={idx}><StepLabel></StepLabel></Step>)}
            </Stepper>

            <Button
                variant="contained"
                className="view_results_btn active"
                disabled={loading.isLoading}
                onClick={clickHandler}>
                {(activeStep === 2 || activeStep === 1 && type === 'call') ? 'Launch on selected date/time' : 'Next'}
            </Button>
        </Stack>
    )
}