import moment from "moment";

const sleep = ms => new Promise((resolve => setTimeout(resolve, ms)));

const keyCapitalize = str => {

    const arr = str?.split("_");

    for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    return  arr.join(" ");
};

const getUniqValuesByKey = (array, keyName) => [...new Set((array || []).map(item => item[keyName]))]

const hasLoading = loadings => Object.values(loadings).filter(loading => loading).length

const getPaginationText = (perPage = 25, text = '') =>  ({count, page}) => {

    if (!count) return '0'

    const lastPage = page * perPage < count ? page * perPage : count

    return `${(page - 1) * perPage + 1} - ${lastPage} of ${count} ${text}`
}

const getDateRange = (firstDate, lastDate) => moment(firstDate).isBefore(lastDate)

export {
    sleep,
    keyCapitalize,
    getUniqValuesByKey,
    hasLoading,
    getPaginationText,
    getDateRange,
}