import React, {useState} from "react";
import {campaignUpdate} from "../../../../../business/redux/reducers/campaign/actions";
import {DateTimeFields} from "../campaignContent/dateTimefields";
import {useDispatch, useSelector} from "react-redux";
import {Modal, Box, Typography, FormControl, InputLabel, OutlinedInput, Stack, Button} from "@mui/material";
import {styleTitle} from "./constant"
import {enqueueSnackbar} from "notistack";

export const EditCampaign = ({ open, setOpen, campaign,setNCampaign, body, setBody}) => {

    const dispatch = useDispatch()

    const [campaignCreateState, setCampaignCreateState] = useState(campaign);

    const {campaigns, auth} = useSelector(s => s);

     const ChangeName = () => {
         return (
             <>
                 <Typography  sx={{ mb: 3}} variant="h2" component="div" fontWeight="600" color="#2B3033">
                     Edit Name
                 </Typography>
                 <FormControl fullWidth sx={{ mb: 5 }} className="filters_field">
                     <InputLabel htmlFor="template_name">Template name</InputLabel>
                     <OutlinedInput
                         value={campaignCreateState.name}
                         onChange={ e => setCampaignCreateState(prev => ({
                             ...prev,
                             name: e.target.value
                         }))}
                         id="template_name"
                         label="Template name"
                     />
                 </FormControl>
             </>
         )
     }

    return (
        <React.Fragment>
            <Modal
                open={open}
                onClose={() => setOpen( !open )}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={styleTitle}>
                    {
                        body === 'date' &&
                        <DateTimeFields
                            campaignCreateState={campaignCreateState}
                            setCampaignCreateState={setCampaignCreateState}
                            dateEditable={true}
                        />
                    }
                    {
                        body === 'name' && <ChangeName />
                    }
                    <Stack display="flex" direction="row" justifyContent="space-between">
                        <Button onClick={() => setOpen( !open )} variant="contained" className="cancle_childModal_btn">Cancel</Button>
                        <Button onClick={() => {
                            dispatch(campaignUpdate(auth?.__raw, campaignCreateState, {enqueueSnackbar, campaigns,setNCampaign}));
                            setOpen( !open )
                        }} variant="contained" className="cancle_childModal_btn">Change</Button>
                    </Stack>
                </Box>
            </Modal>
        </React.Fragment>
    );
}
