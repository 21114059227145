import React from "react";
import {FilterContent} from "../../components/modal/informationModal/contents";
import {CampaignContent} from "../../components/modal/informationModal/contents/campaignContent";
import {openModal} from "../../business/redux/reducers/modal/actions";
import {useDispatch, useSelector} from "react-redux";
import {GridToolbarColumnsButton} from "@mui/x-data-grid";
import {Box, Container, Grid, Button, Typography, Badge} from "@mui/material";
import {FilterAltOutlined, ViewWeekOutlined, EmailOutlined, PhoneOutlined, SmsOutlined} from '@mui/icons-material';
import './style.css';

export const CustomToolbar = ({selectedLeads, open}) => {

    const dispatch = useDispatch();

    const activeButtonClass = open ? " active_create_campaign" : "";

    const {filters} = useSelector(s => s);

    const {leadFilter} = filters;

    const campaignTypes = ['call', 'sms', 'email'];

    const campaignTypeIcon = type => type === 'call'
        ? <PhoneOutlined sx={{mr: 1,fontSize: '24px'}}/>
        : type === 'sms'
            ? <SmsOutlined sx={{mr: 1,fontSize: '24px'}}/>
            : type === 'email'
                ? <EmailOutlined sx={{mr: 1,fontSize: '24px'}}/>
                : <></>

    const filterCount = Object.keys(leadFilter)
        .reduce((acc, curr) => {
            return Array.isArray(leadFilter[curr])
                ? acc + leadFilter[curr].length
                : acc + Number(!!leadFilter[curr])
        }, 0)

    return (
        <Container maxWidth="false"  style={{backgroundColor: '#EBECF0',padding:'5px 8px'}}>
            <Grid container direction="row"  alignItems="center" >
                <Grid item container direction="row" xs={9} sx={{p:0}} alignItems="center">
                    <Box display={'flex'} sx={{p:1}} className={`new_campaign_step_style${activeButtonClass}`}>
                        {campaignTypes.map(campaignType => (
                            <Button
                                key={campaignType}
                                sx={{mr: Number(campaignType !== 'email')}}
                                className="Campaign_btn"
                                onClick={() => dispatch(openModal({
                                    content: <CampaignContent selectedLeads={selectedLeads} type={campaignType}/>,
                                    style: {width: '780px'}
                                }))}
                            >
                                {campaignTypeIcon(campaignType)} {campaignType} campaign
                            </Button>
                        ))}
                        <Typography variant="div" component="span" className="count_campaign_step count_campaign_step_left">
                            2
                        </Typography>
                    </Box>
                    <Typography variant="h6" color="#A8ADB0" component="span" fontStyle="italic" fontWeight="400" >
                        To create a campaign, filter the leads first
                    </Typography>
                </Grid>
                <Grid item container direction="row" xs={3} sx={{p:0}} alignItems="center" justifyContent="flex-end">
                    <GridToolbarColumnsButton sx={{mr:1,height:'40px'}}
                                              className="Campaign_btn"
                                              startIcon={<ViewWeekOutlined sx={{fontSize:'24px'}}/>}
                    />
                    <Box display={'flex'} sx={{p:1}} className={`new_campaign_step_style${activeButtonClass}`}>
                        <Button sx={{mr:0}}
                                className="Campaign_btn"
                                onClick={() => dispatch(openModal ({content: <FilterContent/>, style:{width:'780px'}}))}
                        >
                            <Badge badgeContent={filterCount} // TODO check this
                                   color="primary"
                                   sx={{mr:2}}
                                   className="filter_count"
                            >
                                <FilterAltOutlined sx={{fontSize:'24px'}}/>
                            </Badge> filters
                        </Button>
                        <Typography variant="div" component="span" className="count_campaign_step count_campaign_step_right">
                            1
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}