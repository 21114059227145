import React, {useState} from 'react'
import Box from "@mui/material/Box";
import {styled} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {Button} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {ChildModal} from "../../../childModal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import {DateTimeFields} from "./dateTimefields";
import dayjs from "dayjs";

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 310,
    bgcolor: 'background.paper',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
    borderRadius:'20px',
    pt: 5,
    px: 5,
    pb: 3,
};
const styleTitle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
    borderRadius:'20px',
    pt: 5,
    px: 5,
    pb: 3,
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#F4F5F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const StepThreeContent = ({ campaignCreateState, setCampaignCreateState }) => {

    const [modalData, setModalData] = useState({
        open: false,
        content: <></>,
    });

    const BodyEditCampTitle = ({campaignCreateState, setCampaignCreateState}) => {

        const [name, setName] = useState(campaignCreateState.name)

        const handleName = ()=>{
            setCampaignCreateState( prev => ({
                ...prev,
                    name: name
            }))
        }

        return (
            <Box sx={styleTitle}>
                <Typography sx={{mb: 3}} variant="h2" component="div" fontWeight="600" color="#2B3033">
                    Edit campaign title
                </Typography>
                <FormControl fullWidth sx={{mb: 5}} className="filters_field">
                    <InputLabel htmlFor="campaign_tittle">Campaign tittle</InputLabel>
                    <OutlinedInput
                        value={name}
                        onChange={e => setName( e.target.value )}
                        id="campaign_title"
                        label="Campaign title"
                    />
                </FormControl>
                <Stack display="flex" direction="row" justifyContent="space-between">
                    <Button
                        onClick={() => setModalData({...modalData, open: false})}
                        variant="contained" className="cancle_childModal_btn">Cancel</Button>
                    <Button variant="contained" onClick={() => {
                        handleName();
                        setModalData({...modalData, open: false})
                    }} className="view_results_btn active">Save changes</Button>
                </Stack>
            </Box>
        )
    };

    const bodyEditDateTime = (
        <Box sx={styles} className="edit_modal">
            <Typography variant="h2" component="div" fontWeight="600" color="#2B3033" mb={2}>
                Edit start date and time
            </Typography>
            <DateTimeFields campaignCreateState={ campaignCreateState } setCampaignCreateState={ setCampaignCreateState } dateEditable={true}/>
            <Stack display="flex" direction="row" justifyContent="space-between" sx={{mt:3}}>
                <Button
                    onClick={() => setModalData({ ...modalData, open: false })}
                    variant="contained" className="cancle_childModal_btn">Cancel</Button>
                <Button variant="contained" className="view_results_btn active" >Save changes</Button>
            </Stack>
        </Box>
    )
    return (
        <React.Fragment>
            <Box direction="row" height="100%" width="100%">
                <Box  alignItems="start" sx={{padding:'20px'}} fullWidth>
                    <Typography  variant="h2" component="div" fontWeight="600" color="#2B3033" mb={0}>
                        Review campaign summary
                    </Typography>
                </Box>
                <Box direction="row" fullWidth>
                    <TableContainer className='sms_table_style' >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" >
                            <TableBody>
                                <StyledTableRow>
                                    <TableCell align="left">Campaign title</TableCell>
                                    <TableCell align="left">{ campaignCreateState.name }</TableCell>
                                    <TableCell align="right">
                                        <Button
                                            variant="text"
                                            onClick={() => setModalData({
                                                open: true,
                                                content: <BodyEditCampTitle campaignCreateState={campaignCreateState}
                                                                            setCampaignCreateState={setCampaignCreateState}/>,
                                            })}
                                        >
                                            Edit
                                        </Button></TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell align="left">Campaign type</TableCell>
                                    <TableCell align="left"> { campaignCreateState.type } </TableCell>
                                    <TableCell align="right"></TableCell>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <TableCell align="left">Start date and time</TableCell>
                                    <TableCell align="left">
                                        {
                                            campaignCreateState.started_date
                                                ? campaignCreateState.started_date.format('DD MMM, YYYY HH:mm:ss')
                                                : dayjs().format('DD MMM, YYYY HH:mm:ss')
                                        }
                                    </TableCell>
                                    <TableCell align="right">
                                            <Button
                                                variant="text"
                                                onClick={() => setModalData({
                                                    open: true,
                                                    content: bodyEditDateTime
                                                })}
                                            >Edit</Button>
                                    </TableCell>
                                </StyledTableRow>
                                {/*<StyledTableRow>*/}
                                {/*    <TableCell align="left">Leads</TableCell>*/}
                                {/*    <TableCell align="left">31 Leads selected. <Typography  variant="body2" component="span" fontWeight="500" color="#FF4C86" >5 Leads excluded</Typography></TableCell>*/}
                                {/*    <TableCell align="right"><Button variant="text">Edit</Button></TableCell>*/}
                                {/*</StyledTableRow>*/}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                {/*<Box direction="row" alignItems="start" sx={{padding:'20px'}} fullWidth>*/}
                {/*    <EdiText*/}
                {/*        viewContainerClassName='my-custom-view-wrapper'*/}
                {/*        type='textarea'*/}
                {/*        editButtonContent='Edit campaign content'*/}
                {/*        saveButtonContent="Apply"*/}
                {/*        cancelButtonContent={<strong>Cancel</strong>}*/}
                {/*        inputProps={{*/}
                {/*            className: 'textarea_campaign_temp',*/}
                {/*            placeholder: 'Enter campaign text here',*/}
                {/*            style: {*/}
                {/*                outline: 'none',*/}
                {/*                minWidth: '10%'*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        value={ campaignCreateState.settings.template }*/}
                {/*        onSave={v => v}*/}
                {/*        renderValue={(value) => {*/}
                {/*            const regexp =  /(?=\[)\[[A-Za-z ]+\](?<=\])/g*/}
                {/*            const valueWithParsedURLS = value.replace(*/}
                {/*                regexp,*/}
                {/*                "<b>$&</b>"*/}
                {/*            ).replace(/(?:\r\n|\r|\n)/g, '<br>');*/}
                {/*            return (*/}
                {/*                <p className="view_templ_cont"*/}
                {/*                    dangerouslySetInnerHTML={{ __html: valueWithParsedURLS }}*/}
                {/*                />*/}
                {/*            )*/}
                {/*        }}*/}
                {/*        // hideIcons={true}*/}
                {/*    />*/}
                {/*    <TextareaAutosize*/}
                {/*        className="textarea_style"*/}
                {/*        placeholder="Enter campaign text here"*/}
                {/*        value={}*/}
                {/*    />*/}
                {/*</Box>*/}
            </Box>


            <ChildModal
                open={modalData.open}
                setOpen={(bool => setModalData({...modalData, open: bool}))}
                modalContent={ modalData.content }
            />

        </React.Fragment>
    )
}