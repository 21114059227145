import {styled} from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

export const StyledTableRow = styled(TableRow)( ({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#F4F5F8',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const styleTitle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
    borderRadius:'20px',
    pt: 5,
    px: 5,
    pb: 3,
};