import React, {useEffect, useRef, useState} from "react";
import {getAllTemplates, modifyTemplate, saveTemplate} from "../../../../../../business/redux/reducers/template/actions";
import {LoadTemplate} from "./loadTemplate";
import {useDispatch, useSelector} from "react-redux";
import {
    CircularProgress,
    Popover,
    TextField,
    Box,
    Button,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Stack,
    TextareaAutosize,
    Typography
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {enqueueSnackbar} from "notistack";
import {shortcodes} from "../../../../../../business/constants";
import ReplayIcon from '@mui/icons-material/Replay';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SendIcon from '@mui/icons-material/Send';

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
    borderRadius:'20px',
    pt: 5,
    px: 5,
    pb: 3,
};

export const StepTwoContent = ({campaignCreateState, setCampaignCreateState, type, sent}) => {

    const textAreaRef = useRef(null);
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [openLoadTemplate, setOpenLoadTemplate] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [customCommand, setCustomCommand] = useState('');
    const [cursorPosition, setCursorPosition] = useState(0);
    const [activeGPT, setActiveGPT] = useState('predefined');
    const [prevTemplate, setPrevTemplate] = useState('');
    const [command, setCommand] = useState('');

    const {templates, auth, loading} = useSelector(s => s);

    useEffect(() => {
        dispatch(getAllTemplates({token: auth?.__raw}))
    }, [auth?.__raw])

    const handleClickSnackbar = () => {
        dispatch(saveTemplate(auth?.__raw, templateName, campaignCreateState.settings.template, enqueueSnackbar, templates));
        setOpen(!open)
    }

    const shortCodeSetHandler = leadField => {
        textAreaRef.current.focus();
        setCursorPosition(prevTemplate.length + leadField.length + 2)
        setPrevTemplate(prev => prev.slice(0, cursorPosition) + ` ${leadField} ` + prev.slice(cursorPosition))
        setCampaignCreateState(prev => ({
            ...prev,
            settings: {
                template: prev.settings.template.slice(0, cursorPosition) + ` ${leadField} ` + prev.settings.template.slice(cursorPosition)
            }
        }));
    }

    const changeSavedTemplate = text => {
        textAreaRef.current.focus();
        setPrevTemplate(text)
        setCampaignCreateState(prev => ({
            ...prev,
            settings: {
                template: text
            }
        }));
        setOpenLoadTemplate( !openLoadTemplate )
    }

    const handleChange = (event) => {
        setCommand(event.target.value);
        modifyTemplate(auth?.__raw, prevTemplate, event.target.value, dispatch).then(response => {
            setCampaignCreateState( prev => ({
                ...prev,
                settings: {
                    template: response.data.content
                }
            }))
        })
    };

    const gptCommands = [
        'Make this Paragraph more Friendly',
        'Make this Paragraph more Funny',
        'Make this Paragraph more Serious',
        'Make this Paragraph sound like Keith Cunningham',
        'Make this Paragraph sound Professional',
        'Make this Paragraph Shorter',
        'Make this Paragraph more Sales oriented',
    ];

    return(
        <React.Fragment >
            <Grid container direction="row" height="100%">
                <Grid item xs={8}  alignItems="start" sx={{padding:'20px'}}>
                    <Typography  variant="h2" component="div" fontWeight="600" color="#2B3033" mb={2}>
                        Campaign content
                    </Typography>

                    {type === 'email' &&
                        <FormControl fullWidth sx={{mb: 3}} className="filters_field">
                            <TextField
                                id="campaign_title"
                                style={{marginTop: 20}}
                                variant="outlined"
                                label="Subject"
                                error={sent && !campaignCreateState?.subject}
                                helperText={sent && !campaignCreateState?.subject ? "Please add this field" : ""}
                                value={campaignCreateState.subject}
                                onChange={e => setCampaignCreateState(prev => ({
                                    ...prev,
                                    subject: e.target.value
                                }))}
                            />
                        </FormControl>}

                    <Box className="chat_gpt_content" direction="row"  sx={{mb:3,pl:1}}>
                        <Grid container direction="row"  alignItems="center" sx={{borderBottom:'1px solid #74AA9C',mb:2}}>
                            <Grid item container direction="row" xs={6}  alignItems="center">
                                <Paper variant="outlined" className="logo_gpt">
                                    <Box width="22px" height="22px" component="img"  src="./images/icons-chatgpt.png" />
                                </Paper>
                                <Typography  variant="div" component="div" fontWeight="500" color="#2B3033" className="logoTitle">
                                    Use ChatGPT
                                </Typography>
                            </Grid>
                            <Grid item container direction="row" xs={6} alignItems="center" justifyContent="flex-end">
                                <Button
                                    sx={{ml:1}}
                                    className={activeGPT === 'predefined' ? "btn_def_gpt active_btn_gpt" : "btn_def_gpt"}
                                    onClick={() => setActiveGPT('predefined')}
                                >
                                    Predefined
                                </Button>
                                <Button
                                    sx={{ml:1}}
                                    className={activeGPT === 'command' ? "btn_def_gpt active_btn_gpt" : "btn_def_gpt"}
                                    onClick={() => setActiveGPT('command')}
                                >
                                    Command
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container direction="row"  alignItems="center" sx={{borderBottom:'1px solid #74AA9C',pb:2,mb:2}}>
                            <Grid item container direction="row" xs={9}  alignItems="center">
                                <Box width="100%">
                                    {activeGPT === 'predefined' &&
                                        <FormControl fullWidth>
                                            <TextField
                                                className="select_gpt"
                                                select
                                                id="select_commands_gpt"
                                                value={command}
                                                label={ !command ? "Ask chatGpt" : ""}
                                                InputLabelProps={{shrink: false}}
                                                onChange={handleChange}
                                                IconComponent={
                                                    <KeyboardArrowDownIcon />
                                                }
                                            >
                                                {gptCommands.map(command =>
                                                    <MenuItem
                                                        key={command}
                                                        className="commands_gpt_item"
                                                        value={command}
                                                    >
                                                        {command}
                                                    </MenuItem>)}
                                            </TextField>
                                        </FormControl>}
                                    {activeGPT === 'command' &&
                                        <Box display={'flex'} >
                                            <TextField
                                                fullWidth
                                                autoFocus
                                                variant="filled"
                                                className="input_gpt"
                                                id="select_commands_gpt"
                                                value={customCommand}
                                                onChange={e => setCustomCommand(e.target.value)}
                                            />
                                            <Button
                                                sx={{ml:1}}
                                                className="btn_input_gpt"
                                                variant="text"
                                                onClick={() => handleChange({target: {value: customCommand}})}
                                            >
                                                <SendIcon color={'#74AA9C'} hove/>
                                            </Button>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                            <Grid item container direction="row" xs={3} alignItems="center" justifyContent="flex-end">
                                <Button
                                    onClick={() => {
                                        setActiveGPT('predefined')
                                        setCommand('')
                                        setCustomCommand('')
                                        setCampaignCreateState(prev => ({...prev, settings: {template: prevTemplate}}))
                                    }}
                                    variant="text" sx={ !command ? "opacity:0.5": "opacity:1"} className="undo_btn">
                                    <ReplayIcon sx={{mr:1,fontSize:'20px'}}/> UNDO
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box direction="row" display="flex" justifyContent="space-between">
                        <Button
                            sx={{mb: 3}}
                            className="btn_gray_style"
                            onClick={() => setOpenLoadTemplate(!openLoadTemplate)}
                        >
                            <UploadFileIcon sx={{mr:1,fontSize:'20px'}}/> Load a template
                        </Button>
                        <Button sx={{mb:3}} className="btn_gray_style" onClick={ () => setOpen( !open ) }><SaveOutlinedIcon sx={{mr:1,fontSize:'20px'}}/> Save as template
                        </Button>
                    </Box>

                    {loading.textareaLoading &&
                        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <CircularProgress/>
                        </Box>}

                    {!loading.customLoading &&
                        <TextareaAutosize
                            ref={textAreaRef}
                            valid
                            className="textarea_style"
                            placeholder="Enter campaign text here"
                            value={campaignCreateState.settings.template}
                            onClick={e => setCursorPosition(e.target.selectionEnd)}
                            onChange={e => {
                                setCursorPosition(e.target.selectionEnd)
                                setPrevTemplate(e.target.value)
                                return setCampaignCreateState(prev => ({
                                    ...prev,
                                    settings: {
                                        template: e.target.value
                                    }
                                }))
                            }}
                        />
                    }

                </Grid>
                <Grid  item xs={4}  alignItems="start" bgcolor="#EBECF0" height="100%"  sx={{padding:'20px'}}>
                    <Typography  variant="body2" component="div" fontWeight="500" color="#2B3033" mb={3}>
                        Insert shortcode
                    </Typography>
                    <Box justifyContent="start">
                        {shortcodes.map(field => (
                            <Chip
                                key={ field }
                                className="chip_shortcode"
                                label={ field }
                                onClick={ () => shortCodeSetHandler( field ) }
                                clickable
                            />
                        ))}
                    </Box>
                </Grid>
            </Grid>

            <Modal
                open={open}
                onClose={ () => setOpen( !open )}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={styles}>
                    <Typography  sx={{ mb: 3}} variant="h2" component="div" fontWeight="600" color="#2B3033">
                        Save as template
                    </Typography>
                    <FormControl fullWidth sx={{ mb: 5 }} className="filters_field">
                        <InputLabel htmlFor="template_name">Template name</InputLabel>
                        <OutlinedInput
                            id="template_name"
                            value={ templateName }
                            onChange={ e => setTemplateName(e.target.value) }
                            label="Template name"
                        />
                    </FormControl>
                    <Stack display="flex" direction="row" justifyContent="space-between">
                        <Button onClick={ () => setOpen( !open ) } variant="contained" className="cancle_childModal_btn">Cancel</Button>
                        <Button variant=" contained " className="view_results_btn active" onClick={ handleClickSnackbar }> Save Template </Button>
                    </Stack>
                </Box>
            </Modal>

            {/*load template section*/}
            <Popover
                open={openLoadTemplate}
                anchorEl={openLoadTemplate}
                className="poper_template"
                marginThreshold={0}
                onClose={ () => setOpenLoadTemplate( !openLoadTemplate) }
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
            >
                <LoadTemplate
                    templates={templates}
                    setOpenLoadTemplate={setOpenLoadTemplate}
                    clickHandler={changeSavedTemplate}
                />
            </Popover>
        </React.Fragment>
    )
}
