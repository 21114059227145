import React, {useState} from "react";
import Modal from "@mui/material/Modal";


export const ChildModal = ({open, setOpen, modalContent}) => (
    <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
    >
        {modalContent}
    </Modal>
);

