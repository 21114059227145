import * as React from 'react';
import ReactDOM from 'react-dom/client';
import {App} from './App';
import {SnackbarProvider} from 'notistack';
import "./product/styles/GlobalStyle.css";
import {Auth0ProviderWithNavigate} from "./product/providers";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Auth0ProviderWithNavigate>
        <SnackbarProvider maxSnack={3}>
            <App/>
        </SnackbarProvider>
    </Auth0ProviderWithNavigate>
);