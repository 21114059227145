import React, {useEffect, useState} from "react";
import {getPaginationText} from "../../business/helpers/util";
import {columns} from './columns';
import {DataGrid} from "@mui/x-data-grid";
import {CustomPagination} from "../../components/pagination";
import {getAllCampaignLogs} from "../../business/redux/reducers/log/actions";
import {CustomToolbar} from "./customToolbar";
import {useDispatch, useSelector} from "react-redux";
import {CircularProgress, Box} from "@mui/material";
import './style.css';


export const Logs = () => {

    const dispatch = useDispatch();
    const {logs, auth,loading} = useSelector(s => s);

    const [searchText, setSearchText] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedLeads, setSelectedLeads] = useState({});

    useEffect(() => {
        dispatch(getAllCampaignLogs({token: auth?.__raw, tableLoading: true}));
    }, [auth?.__raw]);

    if (loading.isLoading)
        return <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></Box>

    const paginationModelChangeHandler = e => dispatch(getAllCampaignLogs({
        token: auth?.__raw,
        paginationParams: {per_page: e.pageSize, page: 1, order: 'desc'},
        tableLoading: true
    }))

    const paginationChangeHandler = (newPage, data) => {

        const paginationParams = {per_page: data?.per_page || 25, page: newPage, order: 'desc'};

        dispatch(getAllCampaignLogs({
            token: auth?.__raw,
            tableLoading: true,
            paginationParams
        }))
    }

    const filteredData = (logs?.data || []).filter(el =>
        el.user_name.includes(searchText) ||
        el.job_number.includes(searchText) ||
        el.campaign_name.includes(searchText)
    )

    return (
        <Box sx={{width: '100%', height: '88vh'}}>
            <DataGrid
                className="custom_table logs_table"
                rows={filteredData}
                columns={columns(dispatch)}
                slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination
                }}
                slotProps={{
                    toolbar: {
                        searchText,
                        setSearchText,
                        open,
                        setOpen
                        // filterParams,
                        // setFilterParams
                    },
                    pagination: {data: logs, onChange: paginationChangeHandler}
                }}
                onPaginationModelChange={paginationModelChangeHandler}
                initialState={{pagination: {paginationModel: {pageSize: 25}}}}
                pageSizeOptions={[25, 50, 100]}
                rowCount={logs?.total}
                getRowClassName={r => r.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                loading={loading?.tableLoading}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Results per page /',
                        labelDisplayedRows: getPaginationText(logs?.perPage || 25, 'logs')
                    }
                }}
            />
        </Box>
    )
}