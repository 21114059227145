import React, {useRef} from "react";
import {useDispatch} from "react-redux";
import {openModal} from "../../business/redux/reducers/modal/actions";
import {TextField, Container, Grid} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {TemplateCreateContent} from "../../components/modal/informationModal/contents";

export const CustomToolbar = ({searchText, setSearchText}) => {

    const dispatch = useDispatch();

    return (
        <Container  maxWidth="false"  style={{backgroundColor: '#EBECF0', padding: '5px 8px'}} >
            <Grid container direction="row"  alignItems="center" justifyContent="space-between">
                <Grid item container direction="row" xs={3} sx={{p: 1}} alignItems="center">
                    <FormControl className="search_field filters_field" fullWidth >
                        <TextField
                            value={searchText}
                            variant="outlined"
                            placeholder="Serach"
                            onChange={e => setSearchText(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item container direction="row" xs={3} sx={{p:1}} alignItems="center" justifyContent="flex-end">
                    <Button
                        sx={{mr: 1}}
                        className="Campaign_btn"
                        onClick={() => dispatch(openModal({
                            open: true,
                            content: <TemplateCreateContent />}))}
                    >
                        <AddIcon sx={{mr: 1, fontSize: '24px', color: '#007BFF'}}/> new template
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}