import React, {useState} from "react";
import {EditCampaign} from "./editCampaign";
import {CampaignActionButtons} from "./campaignActionButtons";
import {TableCell, TableContainer, Table, TableBody, Button, Grid} from "@mui/material";
import {StyledTableRow} from "./constant";
import {SmsOutlined} from "@mui/icons-material";

export const SmsCampaignDetails = ({campaign} ) => {

    const [nCampaign, setNCampaign] = useState(campaign);
    const [open, setOpen ] = useState(false);
    const [body,setBody] = useState('');

    return (
        <>
            <TableContainer className='sms_table_style' >
                <Table  aria-label="simple table" >
                    <TableBody>
                        <StyledTableRow>
                            <TableCell align="left">Campaign title</TableCell>
                            <TableCell align="left">{nCampaign.name}</TableCell>
                            <TableCell align="right">
                                <Button
                                    onClick={() => {
                                        setOpen( !open )
                                        setBody('name')
                                    }}
                                    variant="text"
                                    disabled={ ['completed','archived'].includes(nCampaign.status)}
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell align="left">Type</TableCell>
                            <TableCell align="left">
                                <Grid container direction="row" alignItems="center">
                                    <SmsOutlined sx={{mr:1,fontSize:'24px',color:'#007BFF'}}/>
                                    SMS
                                </Grid>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell align="left">Date Created</TableCell>
                            <TableCell align="left">{nCampaign.createdAt}</TableCell>
                            <TableCell align="right"></TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell align="left">Start date and time</TableCell>
                            <TableCell align="left">{nCampaign.startedDate}</TableCell>
                            <TableCell align="right">
                                <Button
                                    onClick={() => {
                                        setOpen( !open )
                                        setBody('date')
                                    }}
                                    disabled={ ['completed','archived'].includes(nCampaign.status)}
                                    variant="text"
                                >
                                    Edit
                                </Button>
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell align="left">Leads</TableCell>
                            <TableCell align="left">{nCampaign?.leadsCount}</TableCell>
                            <TableCell align="right">
                                <Button
                                    onClick={() => {
                                        setOpen( !open )
                                        setBody('')
                                    }}
                                    variant="text"
                                    disabled={ ['completed','archived'].includes(nCampaign.status)}
                                > Edit </Button>
                            </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell align="left">Template used</TableCell>
                            <TableCell align="left">{nCampaign?.settings?.template || ""}</TableCell>
                            <TableCell align="right"></TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">{nCampaign.status}</TableCell>
                            <TableCell align="right"></TableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <CampaignActionButtons campaign={campaign} />

            < EditCampaign
                open={open}
                setOpen={setOpen}
                campaign={nCampaign}
                setNCampaign={setNCampaign}
                body={body}
            />
        </>
    )
};
