import React, {useState} from "react";
import {Button, Box, Typography, FormControl, InputLabel, OutlinedInput, Stack} from "@mui/material";

export const EditTemplateForm = ({changedTemplate, setChangedTemplate, setModalData, styleTitle}) => {

    const [name, setName] = useState(changedTemplate?.name);

    const updateTemplateName = () => {
        setChangedTemplate(prev => ({...prev, name }))
        setModalData(prev => ({...prev, open: false}))
    }

    return (
        <Box sx={styleTitle}>
            <Typography sx={{mb: 3}} variant="h2" component="div" fontWeight="600" color="#2B3033">
                Edit template name
            </Typography>
            <FormControl fullWidth sx={{mb: 5}} className="filters_field">
                <InputLabel htmlFor="template_name">Template name</InputLabel>
                <OutlinedInput
                    id="template_name"
                    label="Template name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                />
            </FormControl>
            <Stack display="flex" direction="row" justifyContent="space-between">
                <Button onClick={() => setModalData(prev => ({...prev, open: false}))} variant="contained"
                        className="cancle_childModal_btn">Cancel</Button>
                <Button variant="contained" className="view_results_btn active"
                        onClick={updateTemplateName}>Save
                    template</Button>
            </Stack>
        </Box>
    )
}