import React, {useState} from "react";
import {Box, Button, FormControl, InputAdornment, TextField, Typography} from "@mui/material";
import {HighlightOff, Search} from "@mui/icons-material";
import {TemplateList} from "./templateList";

export const LoadTemplate = ({
                                 templates,
                                 setOpenLoadTemplate,
                                 clickHandler
                             }) => {

    const [searchText, setSearchText] = useState('');

    const filteredData = (templates?.data || []).filter(el => el?.name.includes(searchText));

    return (
        <Box sx={{height: '100%'}} bgcolor="#d6d6d9b0">
            <Box bgcolor="#f4f5f8b3" px={2} py={2}>
                <Box direction="row" display="flex" justifyContent="space-between" mb={2}>
                    <Typography  variant="h2" component="div" fontWeight="600" color="#2B3033" >
                        Load a template
                    </Typography>
                    <Button onClick={ () => setOpenLoadTemplate(prev => !prev) } variant="text" sx={{py:0, minWidth:'unset'}}>
                        <HighlightOff sx={{ color: '#2B3033',fontSize:'22px'}}/>
                    </Button>
                </Box>
                <Box direction="row">
                    <FormControl className="search_field filters_field" fullWidth >
                        <TextField
                            variant="outlined"
                            placeholder="Serach"
                            onChange={e => setSearchText(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </FormControl>
                </Box>
            </Box>
            <Box  px={2} py={2} sx={{height: 'calc(100% - 170px)'}}>
                <TemplateList templates={filteredData} clickHandler={clickHandler} />
            </Box>
        </Box>

    )
}