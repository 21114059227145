import {SET_LEAD_FILTER_PARAMS, SET_LOG_FILTER_PARAMS, FILTERS_INIT_STATE} from "./types";

export const filtersReducer = (state = FILTERS_INIT_STATE, action) => {

    switch (action.type) {
        case SET_LEAD_FILTER_PARAMS:
            return {
                ...state,
                leadFilter: action.params
            };
        case SET_LOG_FILTER_PARAMS:
            return {
                ...state,
                logFilter: action.params
            };
        default:
            return state
    }
}