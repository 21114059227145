import React from "react";
import {Box, Button, Grid} from "@mui/material";
import {CheckBoxGroup} from "../../../components/global/checkboxes";

export const Filter = ({filterParams, setFilterParams}) => {

    const getClassName = checked => checked ? "filter_items_btn active" : "filter_items_btn";

    const statusClickHandler = (key, value) => () => setFilterParams({
        ...filterParams,
        statuses: {
            ...filterParams.statuses,
            [key]: {...value, checked: !value.checked}
        }
    })

    const typeClickHandler = (key, value) => () => setFilterParams({
        ...filterParams,
        types: {
            ...filterParams.types,
            [key]: {...value, checked: !value.checked}
        },
    })

    const allTypeClickHandler = allData => () => setFilterParams({...filterParams, types: allData})

    return (
        <Grid item container direction="row" xs={9} sx={{p:1}} alignItems="center">

            <Box>
                {Object.entries(filterParams.statuses).map(([key, value]) => (
                    <Button
                        key={key}
                        className={getClassName(value.checked)}
                        onClick={statusClickHandler(key, value)}
                    >
                        {value.name}
                    </Button>
                ))}
            </Box>

            <CheckBoxGroup
                params={filterParams.types}
                changeHandler={typeClickHandler}
                allClickHandler={allTypeClickHandler}
                hasAllButton={true}
            />

        </Grid>
    )
}