import React, {Fragment, useState} from "react";
import {Box, Typography} from "@mui/material";
import dayJs from 'dayjs';
import Button from "@mui/material/Button";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {CalendarMonthOutlined} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {setLeadFilterParams} from "../../../business/redux/reducers/filter/actions";

export const DateRange = ({ el }) => {

    const {filters} = useSelector(s => s);

    const {leadFilter} = filters;

    const dispatch = useDispatch();

    const changedFilterData = {};

    Object.keys(leadFilter).forEach(key => {
        if (leadFilter[key]) {
            changedFilterData[key] = leadFilter[key]
        }
    })

    const [selectedDateRangeInterval, setSelectedDateRangeInterval] = useState('');

    const checkClassName = value => {
        return value === selectedDateRangeInterval
            ? "chip_filter_btn chip_active"
            : "chip_filter_btn";
    }

    const setThisMonth = e => {
        dispatch(setLeadFilterParams({
            ...leadFilter,
            [`${el.fieldName}_from`]: dayJs(moment().startOf('month').format()),
            [`${el.fieldName}_to`]: dayJs(moment().endOf('month').format())
        }))
        setSelectedDateRangeInterval(e.target.innerText)
    }

    const setThisWeek = e => {
        dispatch(setLeadFilterParams({
            ...leadFilter,
            [`${el.fieldName}_from`]: dayJs(moment().startOf('week').format()),
            [`${el.fieldName}_to`]: dayJs(moment().endOf('week').format()),
        }))
        setSelectedDateRangeInterval(e.target.innerText)
    }

    const setLastMonth = e => {
        dispatch(setLeadFilterParams({
            ...leadFilter,
            [`${el.fieldName}_from`]: dayJs(moment().add(-1, 'M').startOf('month').format()),
            [`${el.fieldName}_to`]: dayJs(moment().add(-1, 'M').endOf('month').format())
        }))
        setSelectedDateRangeInterval(e.target.innerText)
    }

    const setLastWeek = e => {
        dispatch(setLeadFilterParams({
            ...leadFilter,
            [`${el.fieldName}_from`]: dayJs(moment().add(-1, 'week').startOf('week').format()),
            [`${el.fieldName}_to`]: dayJs(moment().add(-1, 'week').endOf('week').format())
        }))
        setSelectedDateRangeInterval(e.target.innerText)
    }

    const setLast30Days = e => {
        dispatch(setLeadFilterParams({
            ...leadFilter,
            [`${el.fieldName}_from`]: dayJs(moment().add(-30, 'day').format()),
            [`${el.fieldName}_to`]: dayJs(moment().format())
        }))
        setSelectedDateRangeInterval(e.target.innerText)
    }

    const setLast7Days = e => {
        dispatch(setLeadFilterParams({
            ...leadFilter,
            [`${el.fieldName}_from`]: dayJs(moment().add(-7, 'day').format()),
            [`${el.fieldName}_to`]: dayJs(moment().format())
        }))
        setSelectedDateRangeInterval(e.target.innerText)
    }

    return (
        <Fragment>
            <Typography
                variant="p"
                component="div"
                fontWeight="500"
                fontStyle="italic"
                color="#A8ADB0"
            >
                {el.description}
            </Typography>

            <Box display="flex" flexWrap="wrap" sx={{my: 2}}>
                <Button onClick={setThisMonth} variant="text"  className={checkClassName("This month")} sx={{mb: 2, mr: 1}}>
                    This month
                </Button>
                <Button onClick={setLastMonth} variant="text"  className={checkClassName("Last month")} sx={{mb: 2, mr: 1}}>
                    Last month
                </Button>
                <Button onClick={setThisWeek} variant="text"  className={checkClassName("This week")} sx={{mb: 2, mr: 1}}>
                    This week
                </Button>
                <Button onClick={setLastWeek} variant="text"  className={checkClassName("Last week")} sx={{mb: 2, mr: 1}}>
                    Last week
                </Button>
                <Button onClick={setLast30Days} variant="text"  className={checkClassName("Last 30 days")}
                        sx={{mb: 2, mr: 1}}>
                    Last 30 days
                </Button>
                <Button onClick={setLast7Days} variant="text"  className={checkClassName("Last 7 days")} sx={{mb: 2, mr: 1}}>
                    Last 7 days
                </Button>
            </Box>

            <Box sx={{flexGrow: 1}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            sx={{mb: 2}}
                            className="date_picker_field"
                            label="From"
                            format="DD, MMMM, YYYY"
                            onChange={from => {
                                dispatch(setLeadFilterParams({
                                    ...leadFilter,
                                    [el.fieldName + '_from']: from
                                }))
                                setSelectedDateRangeInterval("");

                            }}
                            value={leadFilter[`${el.fieldName}_from`] ? dayJs(leadFilter[`${el.fieldName}_from`]) : null}
                            slots={{
                                OpenPickerIcon: CalendarMonthOutlined
                            }}
                        />
                    </DemoContainer>
                    <DemoContainer components={['DatePicker']}>
                        <DatePicker
                            className="date_picker_field"
                            label="To"
                            format="DD, MMMM, YYYY"
                            onChange={ to => {
                                dispatch(setLeadFilterParams({
                                    ...leadFilter,
                                    [el.fieldName + '_to']: to
                                }))
                                setSelectedDateRangeInterval("");
                            }}
                            value={leadFilter[`${el.fieldName}_to`] ? dayJs(leadFilter[`${el.fieldName}_to`]) : null}
                            slots={{OpenPickerIcon: CalendarMonthOutlined}}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Box>
        </Fragment>
    )
}
