import React from "react";
import {DateTimeFields} from "./dateTimefields";
import {Fab, Grid, Box, Typography, Paper, FormControl, InputLabel, OutlinedInput, TextField} from "@mui/material";
import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonOffOutlinedIcon from "@mui/icons-material/PersonOffOutlined";

const Item = styled( Paper )({
    backgroundColor: '#F4F5F8',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '20px',
    color: '#2B3033',
    border: 'none',
    boxShadow: 'none'
});
export const StepOneContent = ({ campaignCreateState, setCampaignCreateState, type, sent }) => {

    const {leads} = useSelector(s => s);

    return (
        <React.Fragment>
            <Grid container direction="row" spacing={2} height="100%" sx={{padding: '20px'}}>
                <Grid item xs={6} alignItems="start">
                    <Typography variant="h2" component="div" fontWeight="600" color="#2B3033" mb={2}>
                        Title and date
                    </Typography>
                    <Box>
                        <FormControl fullWidth sx={{mb: 3}} className="filters_field">
                            <TextField
                                id="campaign_title"
                                variant="outlined"
                                label="Campaign title"
                                error={sent && !campaignCreateState?.name}
                                helperText={sent && !campaignCreateState?.name ? "Please add this field" : ""}
                                value={campaignCreateState.name}
                                onChange={e => setCampaignCreateState(prev => ({
                                    ...prev,
                                    name: e.target.value
                                }))}
                            />

                            {type === 'call' &&
                                <FormControl className="filters_field" sx={{ mr: 2, mt: 2 }}>
                                    <InputLabel htmlFor="call_delay">Call delay</InputLabel>
                                    <OutlinedInput
                                        id="call_delay"
                                        label="Call delay"
                                        type={'number'}
                                        InputProps={{inputProps: {min: 1}}}
                                        name={'callDelay'}
                                        value={campaignCreateState.settings.callDelay}
                                        onChange={ e=>  setCampaignCreateState( prev => ({
                                            ...prev,
                                            settings: {
                                                callDelay: e.target.value
                                            }
                                        }))}
                                    />
                                </FormControl>}
                        </FormControl>
                    </Box>
                    <DateTimeFields campaignCreateState={ campaignCreateState } setCampaignCreateState={ setCampaignCreateState } dateEditable={ false }/>
                </Grid>
                <Grid item xs={6} mb={4} alignItems="start">
                    <Box mb={3}>
                        <Item>
                            <Fab disabled className="rounded_icon">
                                <PersonOutlineOutlinedIcon sx={{fontSize: '40px'}}/>
                            </Fab>
                            <Typography variant="div" component="div" className="rounded_cout" mb="4px">
                                {campaignCreateState.leads.length} Leads
                            </Typography>
                            <Typography variant="body1" component="div" fontWeight="500">
                                Selected for this campaign
                            </Typography>
                        </Item>
                    </Box>
                    <Box mb={3}>
                        <Item>
                            <Fab disabled className="rounded_icon color_red">
                                <PersonOffOutlinedIcon sx={{fontSize: '40px'}}/>
                            </Fab>
                            <Typography variant="div" component="div" className="rounded_cout color_red" mb="4px">
                                {(leads?.total || 0) - campaignCreateState.leads.length} Leads
                            </Typography>
                            <Typography variant="body1" component="div" fontWeight="500">
                                Excluded from this campaign
                            </Typography>
                        </Item>
                    </Box>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}