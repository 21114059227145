import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getAllLeadsByFilter} from "../../business/redux/reducers/leads/actions";
import {columns} from './constants';
import {DataGrid} from "@mui/x-data-grid";
import {CustomToolbar} from "./customToolbar";
import {useDispatch, useSelector} from "react-redux";
import {
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Box,
    Button,
    Modal,
    Stack,
    Typography
} from "@mui/material";
import {ASSIGNED_LEADS_ROUTE} from "../../business/routes/routes";
import CheckIcon from "@mui/icons-material/Check";
import {getFilter} from "../../components/modal/informationModal/contents/leadsFilter/helpers";
import {setLeadFilterParams} from "../../business/redux/reducers/filter/actions";
import {getPaginationText, hasLoading} from "../../business/helpers/util";
import {CustomPagination} from "../../components/pagination";
import {styles} from './styles';
import './style.css'

export const AssignedLeads = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const {loading, leads, auth, filters} = useSelector(s => s);

    const {leadFilter} = filters;

    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(false);
    const [selectedLeads, setSelectedLeads] = useState({});

    useEffect(() => {
        dispatch(getAllLeadsByFilter({
            token: auth?.__raw,
            tableLoading: true
        }))
    }, [auth?.__raw])


    useEffect(() => {
        const {state} = location;
        if (state?.prevUrl === ASSIGNED_LEADS_ROUTE.path && state?.actionType === 'create_campaign') {
            setOpen(true)
        }
        if (leads?.filter) {
            dispatch(setLeadFilterParams(getFilter(leads?.filter)));
        }
    }, [])

    const closeHandler = () => {
        if (checked) {
            navigate(location.pathname, {})
        }
        setOpen(false);
    }

    const paginationModelChangeHandler = e => {

        if (hasLoading(loading)) return;

        return dispatch(getAllLeadsByFilter({
            token: auth?.__raw,
            paginationParams: {per_page: e.pageSize, page: 1, order: 'desc'},
            filterFormData: leadFilter,
            tableLoading: true
        }))
    }

    if (loading.isLoading)
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><CircularProgress/></Box>

    const allSelected = Object.values(selectedLeads).reduce((el, acc) => acc.concat(el), []);

    const paginationChangeHandler = (newPage, data) => {

        const paginationParams = {per_page: data?.per_page || 25, page: newPage, order: 'desc'};

        dispatch(getAllLeadsByFilter({
            token: auth?.__raw,
            paginationParams,
            filterFormData: leadFilter,
            tableLoading: true
        }))
    }

    return (
        <Box sx={{width: '100%', height: '88vh'}}>
            <DataGrid
                className="custom_table"
                rows={leads?.list || []}
                columns={columns(dispatch)}
                slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination
                }}
                slotProps={{
                    toolbar: {selectedLeads: allSelected, open},
                    pagination: {data: leads, onChange: paginationChangeHandler}
                }}
                onPaginationModelChange={paginationModelChangeHandler}
                initialState={{pagination: {paginationModel: {pageSize: 25}}}}
                pageSizeOptions={[25, 50, 100]}
                rowCount={leads?.total}
                getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                loading={loading?.tableLoading || loading?.isLoading}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: `Results per page /`,
                        labelDisplayedRows: getPaginationText(leads?.per_page || 25, 'leads')
                    }
                }}
                checkboxSelection
                rowSelectionModel={selectedLeads[`page${leads?.current_page}`]}
                onRowSelectionModelChange={e => setSelectedLeads(prev => ({...prev, [`page${leads?.currentPage}`]: e}))}
            />

            <Modal
                open={open}
                onClose={ () => setOpen( !open )}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={styles} className="new_campaign_popup">
                    <Typography  sx={{ mb: 3}} variant="h2" component="div" fontWeight="600" color="#2B3033">
                        New campaign
                    </Typography>
                    <Typography sx={{mb: 4}} variant="div" component="div" fontWeight="400" color="#585B64" >
                        From the LEADS section, filter the leads you want to send the campaign to, then select the campaign type.
                    </Typography>
                    <Stack display="flex" direction="row" justifyContent="space-between">
                        <FormGroup>
                            <FormControlLabel className="label_checkbox_filter dont_show_btn" control={
                                <Checkbox
                                    icon={<CheckIcon/>}
                                    checked={checked}
                                    onChange={() => setChecked(prev => !prev)}
                                    checkedIcon={<CheckIcon/>}
                                />}
                                label="don't show this again"/>
                        </FormGroup>
                        <Button
                            variant="contained"
                            className="view_results_btn active"
                            onClick={closeHandler}
                        >
                            Got it
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </Box>
    )
}
