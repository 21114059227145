import React, {useEffect, useState} from "react";
import {getAllReports} from "../../business/redux/reducers/reports/actions";
import {DataGrid} from "@mui/x-data-grid";
import {CustomToolbar} from "./customToolbar";
import {CustomPagination} from "../../components/pagination";
import {getPaginationText, hasLoading} from "../../business/helpers/util";
import {useDispatch, useSelector} from "react-redux";
import {reportsTableColumns} from "./columns";
import {CLEAR_REPORTS} from "../../business/redux/reducers/reports/types";
import {Box} from "@mui/material";
import './style.css';


export const Reports = () => {

    const dispatch = useDispatch();
    const {reports, auth,loading} = useSelector(s => s);

    const [searchDate, setSearchDate] = useState({});

    useEffect(() => {
        dispatch(getAllReports({token: auth?.__raw, tableLoading: true, dateFilterParams: searchDate}));
        return () => dispatch({type: CLEAR_REPORTS})
    }, [auth?.__raw, JSON.stringify(searchDate)]);

    const paginationChangeHandler = (newPage, data) => {

        const paginationParams = {per_page: data?.per_page || 25, page: newPage, order: 'desc'};

        dispatch(getAllReports({
            token: auth?.__raw,
            paginationParams,
            dateFilterParams: searchDate,
            tableLoading: true
        }))
    }

    const paginationModelChangeHandler = e => {

        if (hasLoading(loading)) return;

        dispatch(getAllReports({
            token: auth?.__raw,
            paginationParams: {per_page: e.pageSize, page: 1, order: 'desc'},
            dateFilterParams: searchDate,
            tableLoading: true
        }))
    }

    return (
        <Box sx={{width: '100%', height: '88vh'}}>
            <DataGrid
                className="custom_table logs_table"
                rows={reports?.data?.map((el, idx) => ({...el, id: idx})) || []}
                columns={reportsTableColumns}
                slots={{
                    toolbar: CustomToolbar,
                    pagination: CustomPagination
                }}
                slotProps={{
                    toolbar: {
                        searchDate,
                        setSearchDate,
                    },
                    pagination: {data: reports, onChange: paginationChangeHandler}
                }}
                onPaginationModelChange={paginationModelChangeHandler}
                initialState={{pagination: {paginationModel: {pageSize: 25}}}}
                pageSizeOptions={[25, 50, 100]}
                rowCount={reports?.reports}
                getRowClassName={r => r.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
                loading={loading?.tableLoading}
                localeText={{
                    MuiTablePagination: {
                        labelRowsPerPage: 'Results per page /',
                        labelDisplayedRows: getPaginationText(reports?.perPage || 25, 'reports')
                    }
                }}
            />
        </Box>
    )
}