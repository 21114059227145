import {baseAxios} from "../../../../services/api";
import {getUrl} from "../../../../services/helper";
import {LOADING_HIDE, LOADING_SHOW, TABLE_LOADING_HIDE, TABLE_LOADING_SHOW} from '../loading/types';
import {api} from '../../../../configs';
import {SET_REPORTS} from "./types";
import {paginationDefaultParams} from "../../../constants";

const getAllReports = ({
                           token,
                           paginationParams = paginationDefaultParams,
                           dateFilterParams = {},
                           showLoading = false,
                           tableLoading = false
                       }) => async dispatch => {
    try {
        if (!token) return null;

        showLoading && dispatch({type: LOADING_SHOW})
        tableLoading && dispatch({type: TABLE_LOADING_SHOW})

        const {data: reports} = await baseAxios(token).get(getUrl(api.report.index), {params: {...paginationParams, ...dateFilterParams}});

        dispatch({type: SET_REPORTS, reports});
    } catch (error) {
        console.error("Error fetching campaigns:", error.message);
    } finally {
        showLoading && dispatch({type: LOADING_HIDE})
        tableLoading && dispatch({type: TABLE_LOADING_HIDE})
    }
};

export {
    getAllReports,
}

