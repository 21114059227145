import React from "react";
import {DateRange} from "../../../../../global/dateRange";
import {MultipleCheckbox} from "../../../../../global/checkboxes/multipleCheckbox";
import {CubicFitRange} from "../../../../../global/cubicFitRange";
import {Typography, Box} from "@mui/material";
import {SearchByField} from "../../../../../global/searchByField";

export const FilterContentByData = ({el}) => (
    <Box sx={{p: 3, width: 382}}>
        <Typography
            sx={{mb: 2}}
            variant="h2"
            component="div"
            fontWeight="700"
            color="#2B3033"
            textTransform="capitalize"
        >
            {el?.displayField}
        </Typography>

        {el.type === 'date_range' && <DateRange el={el}/>}

        {el.type === 'checkbox' && <MultipleCheckbox el={el}/>}

        {el.type === 'number_range' && <CubicFitRange el={el}/>}

        {el.type === 'input' && <SearchByField el={el}/>}

    </Box>
)
